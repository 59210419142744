import React from 'react';
import PropTypes from 'prop-types';
import { useTracking } from '@utilitywarehouse/partner-tracking-react';
import BenefitsTile from 'app/components/modules/OrderSummary/SummarySection/OtherServices/BenefitsTile';
import {
  PRODUCT_BROADBAND,
  PRODUCT_ENERGY,
  PRODUCT_INSURANCE,
  PRODUCT_MOBILE,
  PRODUCT_MOBILE_2,
} from 'app/redux/modules/Bundles/constants';
import { images } from '@utilitywarehouse/partner-ui-assets';
import useEvents from 'app/lib/analytics/AnalyticsProvider/useEvents';
import { formatProductName } from 'app/lib/analytics/AnalyticsProvider/utils';
import { EVENTS } from 'app/lib/analytics/constants';

const getIcon = (productId) => {
  switch (productId) {
    case PRODUCT_ENERGY:
      return images.icons.productIcons.Electricity;
    case PRODUCT_BROADBAND:
      return images.icons.productIcons.Broadband;
    case PRODUCT_MOBILE:
    case PRODUCT_MOBILE_2:
      return images.icons.productIcons.Mobile;
    case PRODUCT_INSURANCE:
      return images.icons.productIcons.Insurance;
    default:
      return null;
  }
};

const OtherServices = ({ otherServices, addService }) => {
  const analytics = useTracking();
  const coreServicesState = useEvents(EVENTS.SUMMARY_PRODUCT_ADD);

  return (
    <>
      {otherServices.map(({ id, price, ...service }) => (
        <BenefitsTile
          key={id}
          id={id}
          icon={getIcon(id)}
          {...service}
          addService={() => {
            addService(id);
            analytics.track(EVENTS.SUMMARY_PRODUCT_ADD, {
              service_id: formatProductName(id),
              ...coreServicesState,
            });
          }}
        />
      ))}
    </>
  );
};

OtherServices.propTypes = {
  addService: PropTypes.func.isRequired,
  otherServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    })
  ),
};

export default OtherServices;
