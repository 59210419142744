import React from 'react';
import { Skeleton } from '@material-ui/lab';
import Tile from 'app/components/modules/OrderSummary/SummarySection/Shared/Tile';
import { Box } from '@material-ui/core';

const TileSkeleton = () => {
  return (
    <Tile
      dashed
      icon={
        <Skeleton
          animation="wave"
          variant="rect"
          width={40}
          height={40}
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}
        />
      }
      title={
        <Skeleton
          animation="wave"
          variant="text"
          width={96}
          height={40}
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          }}
        />
      }
      priceSection={
        <Skeleton
          animation="wave"
          variant="text"
          width={150}
          height={40}
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            marginLeft: '5px',
          }}
        />
      }
    >
      <Box height={65} />
    </Tile>
  );
};

export default TileSkeleton;
