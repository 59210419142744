import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import useStyles from './styles';

const tariffLabelMap = {
  supplier: 'Supplier',
  name: 'Tariff name',
  type: 'Tariff type',
  paymentMethod: 'Payment Method',
  unitRate: 'Unit rate',
  nightUnitRate: 'Night unit rate',
  standingCharge: 'Standing charge',
  endDate: 'End date',
  exitFee: 'Exit fee',
  discounts: 'Discounts',
};

const emptyValueMap = {
  endDate: 'No end date',
  exitFee: 'n/a',
  discounts: 'n/a',
};

function formatValue(key, value) {
  switch (key) {
    case 'standingCharge':
      return `${value} pence per day`;
    case 'unitRate':
    case 'nightUnitRate':
      return `${value} pence per kWh`;
    default:
      return value;
  }
}

const TariffInformationTableRow = ({ label, value }) => {
  const classes = useStyles();
  return (
    <TableRow className={classes.tariffCostTableRow}>
      <TableCell>
        <Typography>{label}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>
          <b>{value}</b>
        </Typography>
      </TableCell>
    </TableRow>
  );
};
TariffInformationTableRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const TariffInformationLabel = ({ tariff }) => {
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {Object.entries(tariffLabelMap).map(([key, label]) => (
            <Fragment key={key}>
              {tariff[key] && (
                <TariffInformationTableRow
                  value={formatValue(key, tariff[key]) || emptyValueMap[key]}
                  label={
                    key === 'unitRate' && tariff.nightUnitRate
                      ? 'Day unit rate'
                      : label
                  }
                />
              )}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TariffInformationLabel.propTypes = {
  tariff: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  ),
};

export default TariffInformationLabel;
