import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FeatureFlagsApi } from 'redux/modules/FeatureFlags/api';
import { LOGIN_TYPE_DUAL, LOGIN_TYPE_EMAIL, LOGIN_TYPE_ID } from './constants';
import DualLogin from './DualLogin';
import EmailLogin from './EmailLogin';
import IDLogin from './IDLogin';

export const Login = (props) => {
  const { loginType } = props;

  switch (loginType) {
    case LOGIN_TYPE_EMAIL:
      return <EmailLogin {...props} />;
    case LOGIN_TYPE_ID:
      return <IDLogin {...props} />;
    case LOGIN_TYPE_DUAL:
    default:
      return <DualLogin {...props} />;
  }
};

Login.propTypes = {
  loginType: PropTypes.oneOf([
    LOGIN_TYPE_DUAL,
    LOGIN_TYPE_EMAIL,
    LOGIN_TYPE_ID,
  ]),
};

const mapStateToProps = (state) => ({
  loginType: FeatureFlagsApi.getFlag(state, 'login.type'),
});

export default connect(mapStateToProps)(Login);
