import {
  getBreakpoints,
  getSpacing,
  getErrorPaletteColor,
  getPrimaryPaletteColor,
} from 'app/lib/theme';

export default (theme) => {
  const primaryPaletteColor = getPrimaryPaletteColor('main')(theme);
  const errorPaletteColor = getErrorPaletteColor('main')(theme);
  const spacing = getSpacing(theme);
  const breakpoints = getBreakpoints(theme);

  return {
    container: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: spacing(6),
      marginTop: spacing(2),
      [breakpoints.down('md')]: {
        marginTop: spacing(0),
        marginBottom: spacing(2),
        padding: spacing(4),
      },
    },
    widthContainer: {
      maxWidth: 1240,
      width: '100%',
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      [breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    title: {
      color: primaryPaletteColor,
      marginBottom: spacing(2),
    },
    inputContainer: {
      display: 'flex',
      flex: 0.48,
      flexDirection: 'column',
    },
    button: {
      height: '100%',
      marginTop: 26,
    },
    partnerIDWarning: {
      color: errorPaletteColor,
    },
  };
};
