import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import TextField from 'modules/Shared/NewTextField';
import styles from './styles';

class IDLogin extends React.Component {
  constructor() {
    super();
    this.state = {
      partnerId: '',
      password: '',
      formComplete: false,
    };
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    const node = this.formNode;
    if (node) {
      const addEvent = node.addEventListener || node.attachEvent;
      // https://stackoverflow.com/questions/37879658/typeerror-can-only-call-node-addeventlistener-on-instances-of-node-on-safari
      const boundAddEvent = addEvent.bind(node);
      boundAddEvent('keypress', this.handleKeyPress, false);
      boundAddEvent('submit', this.handleKeyPress, false);
    }
  }

  UNSAFE_componentWillUnmount() {
    const node = this.formNode;
    if (node) {
      const removeEvent = node.removeEventListener || node.detachEvent;
      const boundRemoveEvent = removeEvent.bind(node);
      boundRemoveEvent('keypress', this.handleKeyPress);
      boundRemoveEvent('submit', this.handleKeyPress);
    }
  }

  updateField = (field, value) => {
    this.setState({ [field]: value });
    this.validate();
  };

  validate = () => {
    const { partnerId, password } = this.state;
    if (partnerId && password) {
      this.setState({ formComplete: true });
    } else {
      this.setState({ formComplete: false });
    }
  };

  handleLogin(event) {
    const { partnerId, password } = this.state;
    const { login } = this.props;
    event.preventDefault();
    event.stopPropagation();
    login(partnerId, password, false);
  }

  handleKeyPress(event) {
    if (event.keyCode === 13) {
      const { login } = this.props;
      const { partnerId, password, formComplete } = this.state;
      event.preventDefault();
      event.stopPropagation();
      if (formComplete) {
        login(partnerId, password, false);
      }
    }
  }

  render() {
    const { error, classes } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.widthContainer}>
          <form action="#" ref={(node) => (this.formNode = node)}>
            <Typography className={classes.title} variant="h2">
              Partner login
            </Typography>
            <div className={classes.formContainer}>
              <TextField
                fullWidth
                textFieldClasses={{ form: classes.inputContainer }}
                dataTestId="PartnerIdInput"
                label="Partner ID"
                value={this.state.partnerId}
                error={!isEmpty(error)}
                errorMessage={error}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value === '' || /^[A-Za-z0-9]+$/.test(value)) {
                    this.updateField('partnerId', value.toUpperCase());
                  }
                }}
                onBlur={this.validate}
                autoComplete="username"
              />
              <TextField
                fullWidth
                textFieldClasses={{ form: classes.inputContainer }}
                dataTestId="PartnerIdPassword"
                label="Password"
                type="password"
                value={this.state.password}
                error={!isEmpty(error)}
                errorMessage={error}
                onChange={(event) =>
                  this.updateField('password', event.target.value)
                }
                onBlur={this.validate}
                autoComplete="current-password"
              />
              <Button
                size="large"
                variant="contained"
                disabled={!this.state.formComplete}
                onClick={(e) => this.handleLogin(e)}
                className={classes.button}
              >
                Sign-in
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

IDLogin.propTypes = {
  error: PropTypes.string,
  login: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles(styles)(IDLogin);
