import React from 'react';
import ServiceTile from 'app/components/modules/OrderSummary/SummarySection/Shared/ServiceTile';
import { images } from '@utilitywarehouse/partner-ui-assets';
import { insuranceProps } from 'app/components/modules/OrderSummary/SummarySection/SelectedServices/Services/propTypes';
import PropTypes from 'prop-types';
import { compact } from 'lodash';
import {
  PRODUCT_BOILER_INSURANCE,
  PRODUCT_INCOME_PROTECTOR,
} from 'app/redux/modules/OrderSummary/constants';
import useFeature from 'app/lib/analytics/Optimizely/useFeature';
import { features, variations } from 'app/lib/analytics/Optimizely/features';

const BOILER_ITEM_CHUNK = {
  id: PRODUCT_BOILER_INSURANCE,
  name: 'Boiler & Home Cover',
  info: new Map([
    [
      'Includes',
      'Boiler Breakdown, Central Heating, Plumbing, Drainage, Electrics, Locks/Security, Roofing & Pests',
    ],
  ]),
};
const BOILER_ADDITIONAL_BENEFITS = [
  'Additional Benefits',
  'Free Annual Boiler Service',
];
const incomeProtectorInfo = new Map([
  [
    'Includes',
    'A cash payment up to a maximum of £750 if you cannot work due to illness, injury or involuntary unemployment',
  ],
  ['Additional Benefits', 'Hospitalisation cover, personal accident cover'],
]);

const getBoilerItem = (boiler, bhcBoilerServiceEnabled) => {
  if (!boiler) {
    return null;
  }

  const item = {
    ...BOILER_ITEM_CHUNK,
    price: boiler.price,
  };

  if (bhcBoilerServiceEnabled) {
    item.info.set(...BOILER_ADDITIONAL_BENEFITS);
  }

  return item;
};

const getIncomeProtectorItem = (incomeProtector) => {
  if (!incomeProtector) {
    return null;
  }

  return {
    id: PRODUCT_INCOME_PROTECTOR,
    name: 'Income Protector',
    price: incomeProtector.price,
    info: incomeProtectorInfo,
  };
};

const Insurance = ({
  insurance: { price, boiler, incomeProtector },
  editService,
  removeService,
  isEditable,
  isPartner,
  isSales,
}) => {
  const { variant: bhcBoilerService } = useFeature(
    features.HIDE_BHC_BOILER_SERVICE
  );
  const bhcBoilerServiceEnabled =
    bhcBoilerService === variations[features.HIDE_BHC_BOILER_SERVICE].OFF;

  const items = compact([
    getBoilerItem(boiler, bhcBoilerServiceEnabled),
    getIncomeProtectorItem(incomeProtector),
  ]);

  return (
    <ServiceTile
      editService={editService}
      removeService={removeService}
      isEditable={isEditable}
      icon={images.icons.productIcons.Insurance}
      title="Insurance"
      items={items}
      price={price}
      isPartner={isPartner}
      isSales={isSales}
    />
  );
};

Insurance.propTypes = {
  insurance: insuranceProps,
  editService: PropTypes.func.isRequired,
  removeService: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isPartner: PropTypes.bool,
  isSales: PropTypes.bool,
};

export default Insurance;
