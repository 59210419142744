import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { ONETRUST_KEY, ONETRUST_ENABLED } from 'config';
import {
  getConsentScopes,
  getCookieGroups,
  ConsentScope,
} from 'app/lib/analytics/OneTrust/getConsentScopes';
import { COOKIE_POLICY_URL } from 'app/config/externalLinks';
import { client as snowplowClient } from '@utilitywarehouse/partner-tracking-adapter-snowplow-web';
import {
  trackConsentDeny,
  trackConsentSelected,
  trackConsentAllow,
} from '@snowplow/browser-plugin-enhanced-consent';
import { useDispatch } from 'react-redux';
import { setSnowplowReady } from 'redux/modules/Analytics/actions';

import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';

/**
 * Manually clear Snowplow cookies. To be called when revolking tracking permissions
 * via OneTrust. OT itself can not remove cookies, and since they are http
 * cookies it is required to unset them, via setting an exipiry date in the
 * past, on the app's server (/server/index.js)
 */
const clearSpCookies = async () => {
  try {
    await fetch('__/clear-cookies', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ names: ['sp', 'spIdService'] }),
    });
  } catch (error) {
    Sentry.log(error, ERRORS.CLEAR_COOKIES);
  }
};

const OneTrust = () => {
  const dispatch = useDispatch();

  /**
   * handler for setting/changing OneTrust consent settings.
   * Primarily, it handles Snowplow anonymisation, which is enabled upon denying
   * the "performance" cookie group via OT.
   *
   * Additionally, Snowplow-dependant services, such as Optimizely, are also
   * affected by these behaviours. Optimizely experiments and flags will cease
   * to function and return to their default "control" values if Snowplow is
   * disabled due to the dependence on the domainUserId value from the latter.
   *
   * In order to ensure that Optimizely unloads/reloads correctly, we're taking
   * advantage of the existing loading page which will appear during the
   * permissions process, controlled via the `snowplowReady` property in state.
   * Doing so will re-create the OptimizelyProvider with the domainUserId, if
   * available, when OT settings are changed.
   */
  const trackConsent = () => {
    dispatch(setSnowplowReady(false));
    const consentScopes = getConsentScopes();
    // return out if there are no scopes
    // this means that OT isn't initialized yet and consent scopes are not
    // loaded, giving a length of 0
    //
    // avoids 0 === 0 happening later on, causing `allow_all` to be sent
    if (consentScopes.length === 0) {
      return;
    }

    const enhancedConsent = {
      consentScopes,
      basisForProcessing: 'consent',
      consentUrl: COOKIE_POLICY_URL,
      consentVersion: '1.0',
      domainsApplied: [
        'https://uw.co.uk/',
        'https://account.uw.co.uk',
        'https://help.uw.co.uk',
        'https://myaccount.uw.co.uk',
      ],
      gdprApplies: true,
    };

    // handle changing snowplow anonymisation depending on
    // the consent choices from OneTrust
    // Stuck this here since it's based on OT directly
    if (consentScopes.includes(ConsentScope.PERFORMANCE)) {
      snowplowClient.disableAnonymousTracking({
        anonymousTracking: false,
        stateStorageStrategy: 'cookieAndLocalStorage',
      });
    } else {
      snowplowClient.enableAnonymousTracking({
        options: { withServerAnonymisation: true },
        stateStorageStrategy: 'none',
      });

      clearSpCookies();

      snowplowClient.clearUserData();
    }

    // Necessary cookies are always included, so
    // consent scope will have at least one item
    if (consentScopes.length === 1) {
      trackConsentDeny(enhancedConsent);
    } else if (consentScopes.length !== getCookieGroups().length) {
      trackConsentSelected(enhancedConsent);
    } else {
      trackConsentAllow(enhancedConsent);
    }

    dispatch(setSnowplowReady(true));
  };

  // env E2E_TESTS can be set during `make web-dev` to disable OT completely.
  // Used during e2e testing to prevent OT's banner from causing issues.
  const oneTrustEnabled = ONETRUST_ENABLED && process.env.E2E_TESTS !== 'true';

  useEffect(() => {
    if (oneTrustEnabled) {
      trackConsent();
      if (window?.OneTrust) {
        window.OneTrust.OnConsentChanged(trackConsent);
      }
    } else {
      dispatch(setSnowplowReady(true));
    }
    // should only run the once, when the OT SDK is available
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.OneTrust]);

  if (!oneTrustEnabled) return null;

  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src={`https://cdn-ukwest.onetrust.com/consent/${ONETRUST_KEY}/OtAutoBlock.js`}
        ></script>

        <script
          src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
          type="text/javascript"
          data-domain-script={ONETRUST_KEY}
        ></script>
        <script type="text/javascript">{'function OptanonWrapper() {}'}</script>
      </Helmet>
    </>
  );
};

export default OneTrust;
