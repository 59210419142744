import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import TextField from 'modules/Shared/NewTextField';
import { useReferralStyles as useStyles } from './styles';

const Referral = ({ fields, validateForm, updateReferralField }) => {
  const classes = useStyles();
  const { referrer, errors } = fields;

  return (
    <div className={classes.container}>
      <TextField
        fullWidth
        value={referrer.customerId}
        errorMessage={errors.customerId}
        error={!isEmpty(errors.customerId)}
        onChange={(_, value) => {
          const customerId = value.replace(/[^0-9]/g, '');
          updateReferralField('customerId', customerId);
          validateForm(customerId);
        }}
        label="Enter UW account number of the referring Customer"
        textFieldClasses={{ form: classes.textFieldClasses }}
      />
    </div>
  );
};

Referral.propTypes = {
  fields: PropTypes.shape({
    referrer: PropTypes.object,
    errors: PropTypes.object,
  }).isRequired,
  validateForm: PropTypes.func.isRequired,
  updateReferralField: PropTypes.func.isRequired,
};

export default Referral;
