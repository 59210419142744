import React from 'react';
import Dialog from 'modules/Shared/Dialog';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Button from 'modules/Shared/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { WarningOutlined } from '@utilitywarehouse/partner-ui-icons';
import ActionButton from 'app/components/modules/OrderSummary/SummarySection/Shared/ActionButton';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1, 0),
    margin: theme.spacing(0, 2, 2, 2),
  },
  icon: {
    color: theme.palette.error.main,
    height: 50,
    width: 50,
  },
  infoText: {
    margin: theme.spacing(1, 0, 2, 0),
    textAlign: 'center',
  },
  removeButton: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(1),
  },
}));

const Index = ({ open, onCancel, product, removeProduct }) => {
  const classes = useStyles();
  return (
    <Dialog onClose={onCancel} open={open}>
      <div className={classes.container}>
        <WarningOutlined className={classes.icon} />
        <Typography variant="h3" color="primary">
          Remove {product}?
        </Typography>
        <Typography className={classes.infoText}>
          Are you sure you want to remove {product} from your quote?
        </Typography>
        <Button
          variant="outlined"
          size="large"
          onClick={removeProduct}
          color="error"
          data-test-id="RequoteConfirm"
        >
          Yes, remove it
        </Button>
        <ActionButton
          onClick={onCancel}
          label="No, keep it"
          data-test-id="RequoteClose"
        />
      </div>
    </Dialog>
  );
};

Index.propTypes = {
  open: PropTypes.bool.isRequired,
  product: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  removeProduct: PropTypes.func.isRequired,
};

export default Index;
