import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import {
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import {
  GasIcon,
  ElectricityIcon,
  InfoOutlined,
} from '@utilitywarehouse/partner-ui-icons';
import { price as pricePropType } from 'app/constants/propTypes';
import { getTariffName, getTariffType } from 'redux/utils/hasTariffChanged';
import { formatPrice, formatNumber } from 'redux/utils/formatter';
import { PLAN_ARREARS, PLAN_BUDGET } from 'redux/modules/Energy/constants';
import ContentDropdown from 'components/modules/Shared/ContentDropdown';
import Link from 'components/modules/Shared/Link';
import { TARIFF_TYPES } from 'redux/modules/Energy/Quote/constants';
import TariffLabel from './TariffLabel';
import useStyles from './styles';

const ServiceIcon = ({ service }) => {
  const classes = useStyles();
  if (service === 'Electricity') {
    return (
      <ElectricityIcon
        className={clsx(classes.tariffInfoIcon, classes.iconElec)}
      />
    );
  }

  if (service === 'Gas') {
    return (
      <GasIcon className={clsx(classes.tariffInfoIcon, classes.iconGas)} />
    );
  }
};
const TariffInfoDropdown = ({ quote }) => {
  const classes = useStyles();
  const [tilOpen, setTilOpen] = useState(false);
  const [costsOpen, setCostsOpen] = useState(false);
  const { supplyType: service } = quote.tariff;

  return (
    <>
      <ContentDropdown
        open={costsOpen}
        fullWidth
        classes={{
          root: classes.tariffInfoDropdown,
          label: classes.tariffInfoDropdownLabel,
          collapse: classes.tariffInfoDropdownContent,
        }}
        label={
          <span className={classes.tariffInfoLabel}>
            <ServiceIcon service={quote.tariff.supplyType} />
            Illustrative {service} costs
          </span>
        }
        onOpen={() => setCostsOpen(!costsOpen)}
      >
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow className={classes.tariffCostTableRow}>
                <TableCell>
                  <Typography>Annual Consumption</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    <b>{formatNumber(quote.annualConsumption)} kWh</b>
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow className={classes.tariffCostTableRow}>
                <TableCell>
                  <Typography>
                    Personal projection - Your estimated annual costs
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography>
                    <b>{formatPrice(quote.personalProjection)}</b>
                  </Typography>
                </TableCell>
              </TableRow>
              {quote.paymentPlan.toLowerCase() === PLAN_BUDGET && (
                <TableRow className={classes.tariffCostTableRow}>
                  <TableCell>
                    <Typography>
                      Initial budget plan - monthly direct debit
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography>
                      <b>{formatPrice(quote.budgetPlan)}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              {quote.paymentPlan.toLowerCase() === PLAN_ARREARS && (
                <TableRow className={classes.tariffCostTableRow}>
                  <TableCell>
                    <Typography>
                      Monthly in arrears means that your monthly direct debit
                      amount will equal your bill amount.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </ContentDropdown>
      <ContentDropdown
        open={tilOpen}
        fullWidth
        classes={{
          root: classes.tariffInfoDropdown,
          label: classes.tariffInfoDropdownLabel,
          collapse: classes.tariffInfoDropdownContent,
        }}
        label={
          <span className={classes.tariffInfoLabel}>
            <ServiceIcon service={quote.tariff.supplyType} />
            {service} tariff information label
          </span>
        }
        onOpen={() => setTilOpen(!tilOpen)}
      >
        <TariffLabel tariff={quote.tariff} />
      </ContentDropdown>
    </>
  );
};

TariffInfoDropdown.propTypes = {
  quote: PropTypes.shape({
    paymentPlan: PropTypes.oneOf([PLAN_ARREARS, PLAN_BUDGET]).isRequired,
    budgetPlan: pricePropType,
    personalProjection: pricePropType,
    annualConsumption: PropTypes.number.isRequired,
    tariff: PropTypes.shape({
      supplyType: PropTypes.string,
    }),
  }).isRequired,
};

const TariffImportantInfo = ({ showSavings, serviceCount }) => {
  const [open, setOpen] = useState(false);
  const multiservice = serviceCount > 1;
  const classes = useStyles();
  return (
    <ContentDropdown
      open={open}
      onOpen={() => setOpen(!open)}
      fullWidth
      classes={{
        root: classes.tariffInfoDropdown,
        label: classes.tariffInfoDropdownLabel,
        collapse: classes.tariffInfoDropdownContent,
      }}
      label={
        <span className={classes.tariffInfoLabel}>
          <InfoOutlined className={classes.tariffInfoIcon} color="error" />
          Important information
        </span>
      }
    >
      <Typography gutterBottom>
        To make sure you know the key facts about your agreement, please take a
        look at the Personal Projection and Tariff Information Label we&apos;ve
        given you.
      </Typography>
      <Typography gutterBottom>
        All prices shown include VAT. If you&apos;d like to discuss different
        payment options and the tariffs for them, please don&apos;t hesitate to
        call us on{' '}
        <Link href="tel:03337773212">0333&nbsp;777&nbsp;3&nbsp;212</Link>.
        We&apos;re happy to help. And if you change your mind, you can cancel
        the agreement within {multiservice ? '30' : '14'} days.
      </Typography>
      <Typography gutterBottom>
        Please remember that any quotations given are estimates based on what
        you&apos;ve told us about your energy usage or property.
      </Typography>
      <Typography gutterBottom>
        If you have smart meters from another supplier, we'll be able to keep
        their smart functionality if your old provider installed smart meters
        like ours. And we're working hard to support all types of smart meters
        by the end of 2021. But, in certain cases, you might temporarily lose
        some smart meter features, for example automatic meter readings. If
        you've got prepayment smart meters, they'll be turned to 'credit mode'
        before you join us – so you won't be left without any energy, and
        you&apos;ll get a bill for your energy instead of having to top the
        meter up. When your switch is complete, get in touch with us and we'll
        turn them back to prepayment meters, or fit new ones at no extra cost.
      </Typography>
      {showSavings && (
        <Typography gutterBottom>
          * We'll beat the Government's 'Energy Price Guarantee' by up to £50
          and we promise to keep them lower than the price cap for as long as
          you remain a customer. Saving are compared to Government's 'Energy
          Price Guarantee' for default energy tariffs based on payment by direct
          debit for typical household consumption of 2,900 kWh electricity and
          12,000 kWh gas. When any changes to the Government's 'Energy Price
          Guarantee' are made, we will adjust our own tariffs to ensure they
          comply with our savings for the date the new Energy Price Guarantee is
          introduced.
        </Typography>
      )}
    </ContentDropdown>
  );
};

TariffImportantInfo.propTypes = {
  showSavings: PropTypes.bool.isRequired,
  serviceCount: PropTypes.number.isRequired,
};

const TariffInformation = ({ tariffs, serviceCount }) => {
  const classes = useStyles();

  const tariffName = getTariffName(tariffs);

  return (
    <>
      <Typography
        variant="h3"
        color="primary"
        className={classes.tariffInfoTitle}
      >
        Details of our {tariffName} tariff
      </Typography>

      {tariffs.electricity && (
        <TariffInfoDropdown quote={tariffs.electricity.quote} />
      )}

      {tariffs.gas && <TariffInfoDropdown quote={tariffs.gas.quote} />}

      <TariffImportantInfo
        showSavings={getTariffType(tariffs) === TARIFF_TYPES.VARIABLE}
        serviceCount={serviceCount}
      />
    </>
  );
};

TariffInformation.propTypes = {
  tariffs: PropTypes.shape({
    gas: PropTypes.shape({
      quote: PropTypes.shape({}),
    }),
    electricity: PropTypes.shape({
      quote: PropTypes.shape({}),
    }),
  }).isRequired,
  serviceCount: PropTypes.number.isRequired,
};

export default TariffInformation;
