export default ({ breakpoints, palette, spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: palette.common.white,
  },
  subtitle: {
    color: palette.common.white,
    margin: spacing(2, 0, 3, 0),
  },
  link: {
    color: palette.common.white,
    cursor: 'pointer',
  },
  image: {
    alignSelf: 'center',
    display: 'none',
    maxHeight: 320,
    transform: 'scaleX(-1)',
    [breakpoints.up('sm')]: {
      display: 'block',
      height: 300,
    },
    [breakpoints.up('md')]: {
      height: 400,
      marginRight: spacing(20),
    },
  },
  partnerSelection: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: spacing(3),
    [breakpoints.up('sm')]: {
      paddingBottom: 0,
    },
  },
});
