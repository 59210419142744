import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { ChevronRightMediumIcon } from '@utilitywarehouse/react-icons';
import RestrictedButton from 'redux/modules/RemoteSession/v2/RestrictedButton';
import { TARIFF_TYPES } from 'redux/modules/Energy/Quote/constants';
import { FIFTY_POUNDS_OFF_MINIMAL_SERVICES_COUNT } from 'redux/modules/OrderSummary/constants';

import TariffSummary from './tariffSummary';
import TariffInformation from './TariffInformation';
import { EVInterestAlert } from './EvInterestAlert';
import useStyles from './styles';

const getTariffIds = (tariffs) => {
  const ids = [];
  if (tariffs.electricity) {
    ids.push(tariffs.electricity.quote.id);
  }
  if (tariffs.gas) {
    ids.push(tariffs.gas.quote.id);
  }
  return ids;
};

const getTitle = (productToRemove, energyQuotes) => {
  let title = `Removing ${productToRemove} will change your energy`;

  if (!productToRemove) {
    if (energyQuotes) {
      title = 'Choose your new energy tariff';
    } else {
      title = 'We have updated this quote to reflect your service selection';
    }
  }
  return title;
};

const RequoteDialog = ({
  open,
  onClose,
  confirmRemoveProduct,
  changes,
  hasEvInterest,
  productToRemove,
  energyQuotes,
  acceptEnergyQuote,
  serviceCount,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const [isTariffFixed, setTariff] = React.useState(true);

  const variableTariff = energyQuotes?.find(
    (quotes) => quotes.type === TARIFF_TYPES.VARIABLE
  );
  const fixedTariff = energyQuotes?.find(
    (quotes) => quotes.type === TARIFF_TYPES.FIXED
  );

  const tariff = isTariffFixed ? fixedTariff : variableTariff;

  const handleClose = () => {
    acceptEnergyQuote(getTariffIds(tariff));
  };

  const handleRemove = () => {
    confirmRemoveProduct();
    acceptEnergyQuote(getTariffIds(tariff));
  };

  const showEvAlert = hasEvInterest && isTariffFixed;

  return (
    <Dialog
      open={open}
      maxWidth={false}
      scroll={isMobile ? 'body' : 'paper'}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle>
        <div className={classes.title}>
          {getTitle(productToRemove, energyQuotes)}
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Typography className={classes.description}>
          {productToRemove && (
            <>
              If you remove {productToRemove} from your bundle, we'll also need
              to update your energy tariff.{' '}
              {energyQuotes && (
                <span className={classes.bold}>
                  Please choose your new energy tariff below.
                </span>
              )}
            </>
          )}
          {!productToRemove && energyQuotes && (
            <>
              You've unlocked{' '}
              <span className={classes.bold}>
                our cheapest fixed energy tariff
              </span>
              , or{' '}
              <span className={classes.bold}>
                £
                {serviceCount >= FIFTY_POUNDS_OFF_MINIMAL_SERVICES_COUNT
                  ? '50'
                  : '20'}{' '}
                off{' '}
              </span>
              the government's energy price cap with our variable tariff
            </>
          )}
        </Typography>
        <div className={classes.detailsContainer}>
          <TariffSummary tariffs={changes.products.from} current />
          <div className={classes.chevronContainer}>
            <ChevronRightMediumIcon className={classes.chevron} />
          </div>
          <TariffSummary
            isQuoteFixed={isTariffFixed}
            switchEnabled={!!fixedTariff}
            onChange={(nextTariff) => setTariff(nextTariff)}
            tariffs={energyQuotes ? tariff : changes.products.to}
          />
        </div>
        <TariffInformation
          serviceCount={serviceCount}
          tariffs={energyQuotes ? tariff : changes.products.to}
        />
        {showEvAlert && <EVInterestAlert />}
      </DialogContent>
      <DialogActions>
        {productToRemove && (
          <>
            <RestrictedButton
              variant="outlined"
              size="large"
              onClick={onClose}
              dataTestId="RequoteClose"
              label={`Keep ${productToRemove}`}
            />
            <RestrictedButton
              variant="contained"
              color="error"
              size="large"
              onClick={handleRemove}
              dataTestId="RequoteConfirm"
              label={`Remove ${productToRemove}`}
            />
          </>
        )}
        {!productToRemove && (
          <RestrictedButton
            variant="contained"
            size="large"
            onClick={energyQuotes ? handleClose : onClose}
            dataTestId="RequoteConfirm"
            label={energyQuotes ? 'Update tariff' : 'Continue'}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

RequoteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  confirmRemoveProduct: PropTypes.func.isRequired,
  productToRemove: PropTypes.string,
  changes: PropTypes.shape({
    products: PropTypes.shape({
      to: PropTypes.shape({
        _requestedServices: PropTypes.arrayOf(PropTypes.string),
      }),
      from: PropTypes.shape({
        _requestedServices: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
  }).isRequired,
  hasEvInterest: PropTypes.bool.isRequired,
  energyQuotes: PropTypes.array,
  serviceCount: PropTypes.number.isRequired,
};

export default RequoteDialog;
