import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  item: {
    '& + &': {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
      '& + &': {
        marginTop: 0,
        marginLeft: theme.spacing(2),
      },
    },
  },
  itemTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: theme.typography.fontWeightMedium,
    textAlign: 'left',
  },
});

export default makeStyles(style);
