import { makeStyles } from '@material-ui/core';
import { colors } from '@utilitywarehouse/colour-system';

const useStyles = makeStyles(({ spacing, typography }) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  totalContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    margin: spacing(0, 2, 2, 2),
  },
  priceCalculationWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: spacing(1),
    marginTop: spacing(1),
  },
  bold: {
    fontWeight: typography.fontWeightMedium,
  },
  green: {
    color: colors.green600,
  },
  marginBottom: {
    marginTop: spacing(10),
  },
  divider: {
    margin: spacing(1, 0),
    width: '100%',
  },
}));

export { useStyles };
