import React from 'react';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import { Divider, Typography } from '@material-ui/core';
import PriceWithDiscount from 'modules/Shared/PriceWithDiscount';
import { formatPrice } from 'redux/utils/formatter';
import { price as priceProp } from 'app/constants/propTypes';
import { useStyles } from './styles';
import { extraDiscountedPricePropType } from '../propTypes';
import { subtractPrices } from 'app/redux/utils/priceUtils';
import { addPrices } from 'app/redux/utils/priceUtils';

const ExtraDiscountedPrice = ({ extraDiscountedPrice }) => {
  const classes = useStyles();
  const { finalPrice, extraDiscounts, initialPrice } = extraDiscountedPrice;

  return (
    <>
      <div className={classes.priceCalculationWrapper}>
        <Typography className={classes.bold}>
          {formatPrice(initialPrice)}
        </Typography>
        {extraDiscounts.map(({ reason, amount }) => (
          <React.Fragment key={reason}>
            <Typography className={clsx(classes.green, classes.bold)}>
              - {formatPrice(amount)}
            </Typography>
            <Typography className={clsx(classes.green, classes.bold)}>
              ({reason})
            </Typography>
          </React.Fragment>
        ))}
      </div>
      <Divider className={classes.divider} />
      <PriceWithDiscount price={finalPrice} hideDesc />
    </>
  );
};

ExtraDiscountedPrice.propTypes = {
  extraDiscountedPrice: extraDiscountedPricePropType,
};

const TotalPrice = ({
  breakdown,
  totalPrice,
  discountedTotalPrice,
  extraDiscountedPrice,
  hasBbTbybPromo,
}) => {
  const classes = useStyles();

  const renderPrice = () => {
    if (extraDiscountedPrice) {
      return (
        <ExtraDiscountedPrice extraDiscountedPrice={extraDiscountedPrice} />
      );
    }

    return (
      <PriceWithDiscount
        price={totalPrice}
        discountedPrice={discountedTotalPrice}
        hideDesc
      />
    );
  };

  let initialCost = breakdown.totalInitialCost;
  let afterPromoPrice;

  if (hasBbTbybPromo) {
    initialCost = subtractPrices(
      initialCost,
      breakdown.services.find((service) => service.name === 'broadband')
        .totalInitialCost
    );
    const totalMonthlyCostBroadband = breakdown.services.find(
      (service) => service.name === 'broadband'
    )?.totalMonthlyCost;

    afterPromoPrice = addPrices(
      extraDiscountedPrice
        ? extraDiscountedPrice.finalPrice
        : discountedTotalPrice,
      totalMonthlyCostBroadband
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.totalContainer}>
        <Typography variant="h6">Your monthly total</Typography>
        {renderPrice()}
        {hasBbTbybPromo && (
          <Typography component="span">
            {'('}
            <Typography component="span" className={classes.bold}>
              {formatPrice(afterPromoPrice)}
            </Typography>{' '}
            {' after 6 months)'}
          </Typography>
        )}
        <Typography component="span">
          Plus{' '}
          <Typography component="span" className={classes.bold}>
            {formatPrice(initialCost)}
          </Typography>{' '}
          added to your first bill
        </Typography>
      </div>
    </div>
  );
};

TotalPrice.propTypes = {
  breakdown: PropTypes.shape({
    totalMonthlyCost: priceProp,
    promoMonthlyCost: priceProp,
    totalInitialCost: priceProp,
    services: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        totalInitialCost: priceProp,
      })
    ),
  }).isRequired,
  totalPrice: priceProp.isRequired,
  discountedTotalPrice: priceProp,
  extraDiscountedPrice: extraDiscountedPricePropType,
  hasBbTbybPromo: PropTypes.bool,
};

export default TotalPrice;
