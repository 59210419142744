import React from 'react';
import { Typography } from '@mui/material';

import { Alert } from 'app/components/modules/Shared/Alert';
import useStyles from './styles';

const EVInterestAlert = () => {
  const classes = useStyles();

  return (
    <Alert severity="info" className={classes.alert}>
      <Typography as="h4" variant="body" marginBottom={1}>
        Electric Vehicle Tariff
      </Typography>
      Selecting this tariff means you’ll need to pay an exit fee if you decide
      to switch to the variable EV Tariff before your fixed term ends.
    </Alert>
  );
};

export { EVInterestAlert };
