import React from 'react';
import ServiceTile from 'app/components/modules/OrderSummary/SummarySection/Shared/ServiceTile';
import { images } from '@utilitywarehouse/partner-ui-assets';
import { broadbandProps } from 'app/components/modules/OrderSummary/SummarySection/SelectedServices/Services/propTypes';
import PropTypes from 'prop-types';

const Broadband = ({
  broadband: {
    price,
    discountedPrice,
    discountDesc,
    broadband,
    homephone,
    oneTimeCost,
  },
  editService,
  removeService,
  isEditable,
  hasBbTbybPromo,
}) => {
  const items = [
    {
      id: 'BROADBAND',
      name: 'Broadband',
      price: broadband.price,
      info: new Map([
        ['Tariff', broadband.name],
        ['Download', broadband.downloadSpeed],
        ['Upload', broadband.uploadSpeed],
      ]),
    },
    homephone
      ? {
          id: 'HOMEPHONE',
          name: 'Homephone',
          price: homephone.price,
          info: new Map([
            ['Line activation', homephone.lineActivation],
            ['Tariff', homephone.name],
            ['Telephone number', homephone.number],
          ]),
        }
      : undefined,
  ].filter((item) => item !== undefined);

  if (oneTimeCost?.value > 0 && !hasBbTbybPromo) {
    items.push({
      id: 'postage',
      name: 'P&P',
      price: oneTimeCost,
      subtext: '(first bill only)',
    });
  }

  return (
    <ServiceTile
      editService={editService}
      removeService={removeService}
      isEditable={isEditable}
      icon={images.icons.productIcons.Broadband}
      title="Broadband"
      items={items}
      price={price}
      discountedPrice={discountedPrice}
      discountDesc={discountDesc}
      hasBbTbybPromo={hasBbTbybPromo}
    />
  );
};

Broadband.propTypes = {
  broadband: broadbandProps,
  editService: PropTypes.func.isRequired,
  removeService: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  hasBbTbybPromo: PropTypes.bool,
};

export default Broadband;
