import {
  getBreakpoints,
  getSpacing,
  getPrimaryPaletteColor,
} from 'app/lib/theme';
import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => {
  const primaryPaletteColor = getPrimaryPaletteColor('main')(theme);
  const spacing = getSpacing(theme);
  const breakpoints = getBreakpoints(theme);

  return {
    container: {
      display: 'flex',
      justifyContent: 'center',
      padding: spacing(0, 4, 4, 4),
      [breakpoints.up('md')]: {
        padding: spacing(0, 0, 2, 0),
      },
    },
    title: {
      color: primaryPaletteColor,
      marginTop: spacing(1),
    },
    subtitle: {
      margin: spacing(2, 0),
    },
    widthContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      maxWidth: 1240,
      width: '100%',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginTop: spacing(4),
      [breakpoints.up('sm')]: {
        justifyContent: 'space-between',
        flexDirection: 'row',
      },
    },
    btnNext: {
      marginTop: spacing(1),
      [breakpoints.up('sm')]: {
        marginTop: spacing(0),
      },
    },
    textFieldClasses: {
      maxWidth: 512,
    },
    alert: {
      marginTop: spacing(2),
    },
    right: {
      justifyContent: 'flex-end',
    },
  };
};

export default makeStyles(styles);
