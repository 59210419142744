import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import { CheckCard as CheckCardUnwrapped } from '@utilitywarehouse/partner-ui-mui-components';

import useStyles from './styles';

const CheckCard = ({ checked, label, onChange, value }) => {
  const classes = useStyles();
  const id = `${value}_RelationshipTypeRadioId`;

  return (
    <CheckCardUnwrapped
      data-test-id={id}
      checked={checked}
      checkCardClasses={{
        root: classes.item,
      }}
      id={id}
      name="relationshipType"
      onChange={onChange}
      value={value}
      variant="radio"
    >
      <CardContent>
        <Typography
          className={classes.itemTitle}
          color="primary"
          variant="body1"
        >
          {label}
        </Typography>
      </CardContent>
    </CheckCardUnwrapped>
  );
};

CheckCard.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export { CheckCard };
