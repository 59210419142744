import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { WarningOutlined } from '@utilitywarehouse/partner-ui-icons';

import { formatPrice } from 'app/redux/utils/formatter';

import Dialog from 'modules/Shared/Dialog';
import Button from 'modules/Shared/Button';
import { price } from 'app/constants/propTypes';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1, 0),
    margin: theme.spacing(0, 2, 2, 2),
    textAlign: 'center',
  },
  icon: {
    color: theme.palette.error.main,
    height: 50,
    width: 50,
  },
  infoText: {
    margin: theme.spacing(1, 0, 2, 0),
    textAlign: 'center',
  },
  ctaSection: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
}));

const TbybDialog = ({
  bbPrice,
  editBroadband,
  hasFFBroadband,
  onCancel,
  open,
  productToRemove,
  removeProduct,
}) => {
  if (productToRemove) {
    return (
      <RemoveProductDialog
        bbPrice={bbPrice}
        onCancel={onCancel}
        open={open}
        productToRemove={productToRemove}
        removeProduct={removeProduct}
      />
    );
  } else if (hasFFBroadband) {
    return <PromoUnlockedDialog onCancel={onCancel} open={open} />;
  } else {
    return (
      <ChangeBbDialog
        editBroadband={editBroadband}
        onCancel={onCancel}
        open={open}
      />
    );
  }
};

TbybDialog.propTypes = {
  bbPrice: price,
  editBroadband: PropTypes.func.isRequired,
  hasFFBroadband: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  productToRemove: PropTypes.string,
  removeProduct: PropTypes.func.isRequired,
};

const PromoUnlockedDialog = ({ onCancel, open }) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={onCancel}
      open={open}
      hasCloseIcon={false}
      confettiBar={true}
    >
      <div className={classes.container}>
        <Typography variant="h3">
          You've unlocked free Full Fibre broadband for 6 months!
        </Typography>
        <Typography className={classes.infoText}>
          We've updated your quote for you.
        </Typography>
        <Button variant="outlined" size="large" onClick={onCancel}>
          Continue
        </Button>
      </div>
    </Dialog>
  );
};

PromoUnlockedDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const RemoveProductDialog = ({
  bbPrice,
  onCancel,
  open,
  productToRemove,
  removeProduct,
}) => {
  const classes = useStyles();
  return (
    <Dialog onClose={onCancel} open={open}>
      <div className={classes.container}>
        <WarningOutlined className={classes.icon} />
        <Typography variant="h3">Are you sure?</Typography>
        <Typography className={classes.infoText}>
          If you remove {productToRemove} from your bundle, your broadband price
          will go up to {formatPrice(bbPrice)} a month?
        </Typography>
        <div className={classes.ctaSection}>
          <Button variant="outlined" size="large" onClick={onCancel}>
            No, keep {productToRemove}
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={removeProduct}
            color="error"
          >
            Yes, remove {productToRemove}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

RemoveProductDialog.propTypes = {
  bbPrice: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  productToRemove: PropTypes.string.isRequired,
  removeProduct: PropTypes.func.isRequired,
};

const ChangeBbDialog = ({ editBroadband, onCancel, open }) => {
  const classes = useStyles();
  return (
    <Dialog
      onClose={onCancel}
      open={open}
      hasCloseIcon={false}
      confettiBar={true}
    >
      <div className={classes.container}>
        <Typography variant="h3">
          You've unlocked free Full Fibre broadband for 6 months!
        </Typography>
        <Typography className={classes.infoText}>
          Take a look at your new broadband options, or you can continue with
          your chosen broadband plan.
        </Typography>
        <Button variant="contained" size="large" onClick={editBroadband}>
          Show my broadband options
        </Button>
        <Button variant="outlined" size="large" onClick={onCancel}>
          Keep my chosen broadband
        </Button>
      </div>
    </Dialog>
  );
};

ChangeBbDialog.propTypes = {
  editBroadband: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default TbybDialog;
