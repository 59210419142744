import React from 'react';
import ServiceTile from 'app/components/modules/OrderSummary/SummarySection/Shared/ServiceTile';
import { images } from '@utilitywarehouse/partner-ui-assets';
import { mobileProps } from 'app/components/modules/OrderSummary/SummarySection/SelectedServices/Services/propTypes';
import PropTypes from 'prop-types';

const Mobile = ({
  mobile: { price, sims },
  editService,
  removeService,
  isEditable,
}) => {
  const items = sims.map((sim) => ({
    id: sim.id,
    name: `${sim.tariff} Sim`,
    price: sim.price,
    info: new Map([
      ['Tariff', sim.tariff],
      ['Allowance', sim.allowance],
    ]),
  }));

  return (
    <ServiceTile
      editService={editService}
      removeService={removeService}
      isEditable={isEditable}
      icon={images.icons.productIcons.Mobile}
      title="Mobile"
      items={items}
      price={price}
    />
  );
};

Mobile.propTypes = {
  mobile: mobileProps,
  editService: PropTypes.func.isRequired,
  removeService: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

export default Mobile;
