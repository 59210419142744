import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'modules/Shared/NewTextField';
import { InputAdornment, Typography } from '@material-ui/core';

export const isDisplayNameValid = (value) =>
  !!value && value.length >= 2 && value.length <= 21;

const RemoteDisplayName = ({ remoteDisplayName = '', setDisplayName }) => {
  return (
    <TextField
      fullWidth
      required
      label="Your preferred name"
      helperText="Name shown to the prospect during the appointment"
      value={remoteDisplayName}
      onChange={(_, value) => setDisplayName(value)}
      InputProps={{
        inputProps: { minLength: 2, maxLength: 21 },
        endAdornment: (
          <InputAdornment position="end">
            <Typography variant="caption" color="textSecondary">
              {remoteDisplayName ? remoteDisplayName.length : 0}/21
            </Typography>
          </InputAdornment>
        ),
      }}
      error={!isDisplayNameValid(remoteDisplayName)}
      errorMessage="A valid display name is required"
    />
  );
};

RemoteDisplayName.propTypes = {
  setDisplayName: PropTypes.func.isRequired,
  remoteDisplayName: PropTypes.string,
};

export default RemoteDisplayName;
