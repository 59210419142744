import {
  getBreakpoints,
  getSpacing,
  getErrorPaletteColor,
} from 'app/lib/theme';

export default (theme) => {
  const errorPaletteColor = getErrorPaletteColor('main')(theme);
  const breakpoints = getBreakpoints(theme);
  const spacing = getSpacing(theme);

  return {
    form: {
      display: 'flex',
      flexDirection: 'column',
      padding: spacing(0, 4, 2, 4),
      width: '100%',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: 'white',
    },
    continueHolder: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginTop: 16,
      marginRight: 15,
      [breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    continueHolderSuccess: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      marginTop: 16,
      [breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    marginTop: {
      marginTop: spacing(2),
    },
    supportingPartnerIDWarning: {
      color: errorPaletteColor,
    },
  };
};
