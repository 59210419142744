import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  dialogActions: {
    justifyContent: 'center',
    padding: theme.spacing(0, 3, 3, 3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 2, 3, 2),
    },
  },
  alertContainer: {
    margin: theme.spacing(1, 0, 3, 0),
  },
  formContainer: {
    padding: theme.spacing(3, 0, 1.5, 0),
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'flex-end',
      padding: theme.spacing(3, 0, 3, 0),
    },
  },
  formSaveButton: {
    margin: theme.spacing(5, 0, 0, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 0, 0, 3),
      minWidth: 160,
    },
  },
  formShareButton: {
    margin: theme.spacing(5, 0, 0, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0, 0, 0, 3),
    },
  },
}));
