import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';

import {
  CUSTOMER_RELATIONSHIP_NEW,
  CUSTOMER_RELATIONSHIP_REFERRAL,
} from 'redux/modules/PartnerLogin/constants';
import useStyles from './styles';
import { CheckCard } from './CheckCard';

const RelationshipSelection = ({
  customerRelationship,
  isReferralActive,
  resetReferrerFields,
  validateForm,
  updateField,
}) => {
  const classes = useStyles();

  const handleOnChange = (value) => {
    if (value === CUSTOMER_RELATIONSHIP_REFERRAL) {
      validateForm();
    } else if (isReferralActive) {
      resetReferrerFields();
    }

    updateField('customerRelationship', value);
  };

  return (
    <>
      <Typography variant="h5" className={classes.subtitle}>
        Were you introduced to this person by an existing UW customer?
      </Typography>
      <Box className={classes.radioContainer}>
        <CheckCard
          checked={customerRelationship === CUSTOMER_RELATIONSHIP_REFERRAL}
          label="Yes"
          onChange={() => handleOnChange(CUSTOMER_RELATIONSHIP_REFERRAL)}
          value={CUSTOMER_RELATIONSHIP_REFERRAL}
        />
        <CheckCard
          checked={customerRelationship === CUSTOMER_RELATIONSHIP_NEW}
          label="No"
          onChange={() => handleOnChange(CUSTOMER_RELATIONSHIP_NEW)}
          value={CUSTOMER_RELATIONSHIP_NEW}
        />
      </Box>
    </>
  );
};

RelationshipSelection.propTypes = {
  customerRelationship: PropTypes.string,
  isReferralActive: PropTypes.bool.isRequired,
  resetReferrerFields: PropTypes.func.isRequired,
  updateField: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
};

export default RelationshipSelection;
