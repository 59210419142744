import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useHistory } from 'react-router-dom';
import { BundlesPage } from 'app/redux/modules/Routes/constants';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    textAlign: 'center',
    alignContent: 'center',
    margin: theme.spacing(1),
  },
  dialogActions: {
    justifyContent: 'end',
    margin: theme.spacing(1),
  },
}));

const RequireOtherServiceDialog = ({ open, onClose, message }) => {
  const classes = useStyles();
  const history = useHistory();

  const goToBundlesPage = useCallback(() => {
    onClose();
    history.push(BundlesPage);
  }, [history, onClose]);
  return (
    <Dialog open={open}>
      <DialogContent className={classes.dialogContent}>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button variant="contained" onClick={goToBundlesPage}>
          Add services
        </Button>
      </DialogActions>
    </Dialog>
  );
};

RequireOtherServiceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default RequireOtherServiceDialog;
