import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import clsx from 'classnames';
import { MultiSwitch } from '@utilitywarehouse/partner-ui-mui-components';
import Price from 'components/modules/Shared/Price';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { getTariffName } from 'app/redux/utils/hasTariffChanged';

function getTariffPriceSum(tariffs) {
  let sum = 0;
  if (tariffs.electricity) {
    sum += tariffs.electricity.quote.budgetPlan.value;
  }

  if (tariffs.gas) {
    sum += tariffs.gas.quote.budgetPlan.value;
  }

  return {
    currency: 'GBP',
    exponent: 2,
    value: sum,
  };
}

const TariffSummary = ({
  tariffs,
  current = false,
  switchEnabled = false,
  onChange,
  isQuoteFixed,
}) => {
  const nextTariff = !isQuoteFixed;
  const classes = useStyles();
  const tariffName = getTariffName(tariffs);

  const options = [
    { value: true, label: 'Fixed' },
    { value: false, label: 'Variable' },
  ];
  return (
    <Card
      variant="outlined"
      className={clsx(classes.details, {
        [classes.current]: current,
      })}
    >
      <CardContent classes={{ root: classes.cardContent }}>
        <Typography className={classes.detailsTitle} color="primary">
          <span
            className={clsx(classes.detailsTitleLabel, {
              [classes.current]: current,
            })}
          >
            {current && 'Previous'}
            {!current && 'New'}
          </span>
        </Typography>
        <Typography
          className={clsx(classes.summaryText, {
            [classes.current]: current,
          })}
        >
          {tariffName}
        </Typography>
        <Price inheritColor price={getTariffPriceSum(tariffs)} />
        {switchEnabled && (
          <MultiSwitch
            classes={{
              root: classes.switch,
              inactiveOptionText: classes.inactiveOptionText,
              activeOptionText: classes.activeOptionText,
            }}
            options={options}
            name="energy_tariff_switch"
            value={isQuoteFixed}
            onChange={() => onChange(nextTariff)}
          />
        )}
      </CardContent>
    </Card>
  );
};

TariffSummary.propTypes = {
  tariffs: PropTypes.shape({
    gas: PropTypes.shape({}),
    electricity: PropTypes.shape({}),
  }).isRequired,
  current: PropTypes.bool,
  switchEnabled: PropTypes.bool,
  onChange: PropTypes.func,
  isQuoteFixed: PropTypes.bool,
};

export default TariffSummary;
