import { makeStyles } from '@material-ui/core';

export default makeStyles(({ palette, spacing, breakpoints }) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: spacing(2),
    [breakpoints.down('md')]: {
      padding: spacing(0, 4, 4, 4),
    },
  },
  title: {
    color: palette.primary.main,
    marginTop: spacing(1),
  },
  subtitle: {
    margin: spacing(2, 0),
  },
  widthContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: 1240,
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: spacing(4),
    [breakpoints.up('sm')]: {
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  },
  btnNext: {
    marginTop: spacing(1),
    [breakpoints.up('sm')]: {
      marginTop: spacing(0),
    },
  },
  textFieldClasses: {
    maxWidth: 512,
  },
  alert: {
    marginTop: spacing(2),
  },
  right: {
    justifyContent: 'flex-end',
  },
}));

export const useReferralStyles = makeStyles(({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  textFieldClasses: {
    maxWidth: 512,
  },
  checkbox: {
    marginTop: spacing(2),
  },
}));
