import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';

import {
  ERROR_NOT_QUALIFIED,
  MANDATORY,
  OPTIONAL,
} from 'redux/modules/PartnerLogin/constants';
import { PARTNER_HELP_MENTORING_URL } from 'config/externalLinks';
import TextField from 'modules/Shared/NewTextField';
import LoadingIndicator from 'modules/Shared/LoadingIndicator';

import copy from './copy';
import styles from './styles';

class IDDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partnerId: null,
      password: null,
    };
    this.supportFormNode = null;
    this.addEventListener = this.addEventListener.bind(this);
    this.removeEventListener = this.removeEventListener.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  UNSAFE_componentWillUnmount() {
    this.removeEventListener();
  }

  addEventListener() {
    const node = this.supportFormNode;
    if (node) {
      const addEvent = node.addEventListener || node.attachEvent;
      // https://stackoverflow.com/questions/37879658/typeerror-can-only-call-node-addeventlistener-on-instances-of-node-on-safari
      const boundAddEvent = addEvent.bind(node);
      boundAddEvent('keypress', this.handleKeyPress, false);
      boundAddEvent('submit', this.handleKeyPress, false);
    }
  }

  removeEventListener() {
    const node = this.supportFormNode;
    if (node) {
      const removeEvent = node.removeEventListener || node.detachEvent;
      const boundRemoveEvent = removeEvent.bind(node);
      boundRemoveEvent('keypress', this.handleKeyPress);
      boundRemoveEvent('submit', this.handleKeyPress);
    }
  }

  isFormValid() {
    return this.state.partnerId && this.state.partnerId.length;
  }

  handleKeyPress(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      if (this.isFormValid() && !this.props.success) {
        const { onSubmit } = this.props;
        const { partnerId, password } = this.state;
        onSubmit(partnerId, password);
      }
    }
  }

  render() {
    const {
      open,
      onSubmit,
      loading,
      error,
      success,
      onClose,
      supportRequired,
      classes,
      within45Days,
    } = this.props;

    const { partnerId, password } = this.state;
    const isMandatory = supportRequired == MANDATORY;
    const passwordErrorMessage =
      error === ERROR_NOT_QUALIFIED ? 'Supporting ID not qualified' : error;

    return (
      <Dialog open={open} onExit={onClose} onEscapeKeyDown={onClose}>
        <DialogTitle onClose={onClose} disableTypography>
          <Typography variant="h6">Supporting Partner login</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {isMandatory && (
            <Typography variant="body1">{copy.mandatorySupport}</Typography>
          )}
          {supportRequired === OPTIONAL && within45Days && (
            <Typography variant="body1">{copy.withinThirty}</Typography>
          )}
          <Typography variant="body1">
            {copy.loginInfo}{' '}
            <Link underline="always" href={PARTNER_HELP_MENTORING_URL}>
              More info
            </Link>
          </Typography>
          {supportRequired === OPTIONAL && (
            <Typography variant="body1">{copy.optionalSupport}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <form
            className={classes.form}
            action="#"
            ref={(ref) => {
              this.removeEventListener();
              this.supportFormNode = ref;
              this.addEventListener();
            }}
          >
            <TextField
              label="Partner ID"
              value={partnerId}
              onChange={(_, value) => {
                if (value === '' || /^[A-Za-z0-9]+$/.test(value)) {
                  this.setState({
                    partnerId: value.toUpperCase(),
                  });
                }
              }}
              errorMessage={passwordErrorMessage}
              error={!isEmpty(passwordErrorMessage)}
            />
            <TextField
              label="Password"
              value={password}
              type="password"
              onChange={(_, value) => {
                this.setState({ password: value });
              }}
              errorMessage={passwordErrorMessage}
              error={!isEmpty(passwordErrorMessage)}
            />
            {success && !loading && (
              <Alert
                severity="success"
                variant="filled"
                classes={{ root: classes.marginTop }}
              >
                Successfully logged in
              </Alert>
            )}
            {!loading && (
              <div
                className={
                  success
                    ? classes.continueHolderSuccess
                    : classes.continueHolder
                }
              >
                <Button
                  className={classes.marginTop}
                  size="large"
                  variant="outlined"
                  data-test-id="SupportingIdCancel"
                  onClick={(event) => {
                    event.preventDefault(); // in case browser treats it as submit
                    event.stopPropagation();
                    onClose();
                  }}
                >
                  {success ? 'Close' : 'Not now'}
                </Button>
                {!success && (
                  <Button
                    className={classes.marginTop}
                    variant="contained"
                    data-test-id="SupportingIdSubmit"
                    disabled={!this.isFormValid()}
                    onClick={() => {
                      onSubmit(partnerId, password);
                    }}
                  >
                    Submit
                  </Button>
                )}
                {success && <div />}
              </div>
            )}
            {loading && (
              <div className={classes.loadingContainer}>
                <LoadingIndicator loading />
              </div>
            )}
          </form>
        </DialogActions>
      </Dialog>
    );
  }
}

IDDialog.propTypes = {
  error: PropTypes.string,
  success: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  supportRequired: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IDDialog);
