import React from 'react';
import { images } from '@utilitywarehouse/partner-ui-assets';
import PropTypes from 'prop-types';
import { price } from 'app/constants/propTypes';
import ServiceTile from 'app/components/modules/OrderSummary/SummarySection/Shared/ServiceTile';

const BillProtector = ({
  billProtector,
  editService,
  removeService,
  isEditable,
}) => {
  const items = [
    {
      id: 'DETAILS',
      name: 'Details',
    },
  ];

  return (
    <ServiceTile
      isEditable={isEditable}
      icon={images.icons.productIcons.BillProtector}
      price={billProtector.price}
      title="Bill Protector"
      editService={editService}
      items={items}
      removeService={removeService}
    />
  );
};

BillProtector.propTypes = {
  billProtector: PropTypes.shape({
    price: price.isRequired,
  }).isRequired,
  removeService: PropTypes.func.isRequired,
  editService: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

export default BillProtector;
