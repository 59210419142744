import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from 'modules/Shared/NewTextField';
import useStyles from './styles';

const RestoreApp = ({ restoreApplication }) => {
  const classes = useStyles();
  const [code, setCode] = useState('');
  const submit = () => {
    restoreApplication(code);
  };

  return (
    <div className={classes.container}>
      <div className={classes.widthContainer}>
        <Typography variant="h4" className={classes.subtitle}>
          To load an application, please enter the application code
        </Typography>
        <div className={classes.textFieldClasses}>
          <TextField
            fullWidth
            label="Application code"
            helperText={'Code shown in the "Continue later" pop-up'}
            value={code}
            onChange={(_, value) => {
              setCode(value);
            }}
          />
        </div>
        <div className={classNames(classes.buttonContainer, classes.right)}>
          <Button
            className={classes.btnNext}
            size="large"
            variant="contained"
            data-test-id="ContinueApplicationBtn"
            onClick={submit}
          >
            Continue application
          </Button>
        </div>
      </div>
    </div>
  );
};

RestoreApp.propTypes = {
  restoreApplication: PropTypes.func.isRequired,
};

export default RestoreApp;
