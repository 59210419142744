import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import BoostBanner from 'modules/Shared/Banners/Boost';
import NavigationPane from 'modules/Shared/NavigationButton/NavigationPane';
import Save from 'app/redux/modules/OrderSummary/containers/saveContainer';
import SummaryContainer from 'app/redux/modules/OrderSummary/containers/summarySectionContainer';
import SectionCentered from 'modules/layout/SectionCentered';

const useStyles = makeStyles((theme) => ({
  boostBanner: {
    marginTop: theme.spacing(3),
    maxWidth: 750,
  },
}));

const OrderSummary = ({ handleNext, nextDisabled, isMobileOnly }) => {
  const classes = useStyles();

  return (
    <>
      <SectionCentered newLayout>
        <BoostBanner classes={{ container: classes.boostBanner }} />
        <SummaryContainer isApplicationComplete={false} />
      </SectionCentered>
      <NavigationPane
        helpCtaEnabled
        back
        next
        nextLabel="Next"
        nextDisabled={nextDisabled}
        nextHandler={handleNext}
        nextdataTestId="OrderConfirmationSubmit"
        // TODO: Remove temporary solution
        // https://linear.app/utilitywarehouse/issue/AQ-3168/block-multisim-mobile-only-deals-proceeding-past-ordersummary
        nextHelperText={
          isMobileOnly &&
          'To continue with Mobile only, you can only have one SIM in your order.'
        }
      >
        <Save />
      </NavigationPane>
    </>
  );
};

OrderSummary.propTypes = {
  nextDisabled: PropTypes.bool.isRequired,
  handleNext: PropTypes.func.isRequired,
  isMobileOnly: PropTypes.bool.isRequired,
};

export default OrderSummary;
