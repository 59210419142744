import { Box, Typography } from '@material-ui/core';
import useStyles from './styles';
import * as React from 'react';
import PropTypes from 'prop-types';

export const FlashSaleBadge = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    // highly customised component as it doesn't exist yet, based on the Badge component in my-account-web which will be deprecated
    <Box component="span" className={classes.flashSaleBadge}>
      <Typography
        component="span"
        variant="caption"
        className={classes.flashSaleBadgeText}
        title={children}
      >
        {children}
      </Typography>
    </Box>
  );
};

export default FlashSaleBadge;

FlashSaleBadge.propTypes = {
  children: PropTypes.node.isRequired,
};
