import PropTypes from 'prop-types';
import { price } from 'app/constants/propTypes';

const extraDiscounts = PropTypes.arrayOf(
  PropTypes.shape({
    reason: PropTypes.string.isRequired,
    amount: price.isRequired,
  })
);

const extraDiscountedPricePropType = PropTypes.shape({
  finalPrice: price.isRequired,
  extraDiscounts: extraDiscounts,
  initialPrice: price.isRequired,
});

export { extraDiscountedPricePropType };
