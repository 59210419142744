import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FeatureFlagsApi } from 'redux/modules/FeatureFlags/api';
import {
  LOGIN_TYPE_DUAL,
  LOGIN_TYPE_EMAIL,
  LOGIN_TYPE_ID,
} from '../Login/constants';
import DualDialog from './DualDialog';
import EmailDialog from './EmailDialog';
import IDDialog from './IDDialog';
import { PartnerLoginApi } from 'app/redux/modules/PartnerLogin/api';
import moment from 'moment';

export const SuportingIdDialog = (props) => {
  const { loginType } = props;

  switch (loginType) {
    case LOGIN_TYPE_EMAIL:
      return <EmailDialog {...props} />;
    case LOGIN_TYPE_ID:
      return <IDDialog {...props} />;
    case LOGIN_TYPE_DUAL:
    default:
      return <DualDialog {...props} />;
  }
};

SuportingIdDialog.propTypes = {
  loginType: PropTypes.oneOf([
    LOGIN_TYPE_DUAL,
    LOGIN_TYPE_EMAIL,
    LOGIN_TYPE_ID,
  ]),
};

const mapStateToProps = (state) => ({
  loginType: FeatureFlagsApi.getFlag(state, 'login.type'),
  // diff() is counting backwards, so a week ago is -7
  within45Days:
    moment(PartnerLoginApi.getPartnerStartDate(state)).diff(moment(), 'days') >
    -45,
});

export default connect(mapStateToProps)(SuportingIdDialog);
