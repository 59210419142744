import React, { Fragment } from 'react';
import moment from 'moment';

const madDateCheck = moment().isSameOrBefore('2019-09-07 07:01') ? 30 : 45;

export default {
  loginInfo: (
    <Fragment>
      If you are being assisted by a Supporting ID, please ask them to login
      below.
    </Fragment>
  ),
  mandatorySupport: (
    <Fragment>
      We can see that you have not yet completed the Getting Started Online
      Training and therefore you are not yet qualified to sign up customers face
      to face.
    </Fragment>
  ),
  withinThirty: (
    <Fragment>
      We can see that you are still within your first {madDateCheck} days since
      joining.
    </Fragment>
  ),
  optionalSupport: (
    <Fragment>
      As you have completed all of your required training, you can continue to
      sign this customer up alone <b>by clicking Not now</b>
    </Fragment>
  ),
};
