import { price } from 'app/constants/propTypes';
import PropTypes from 'prop-types';

export const broadbandProps = PropTypes.shape({
  price: price.isRequired,
  discountedPrice: price,
  discountDesc: PropTypes.string,
  broadband: PropTypes.shape({
    name: PropTypes.string,
    price: price,
    downloadSpeed: PropTypes.string,
    uploadSpeed: PropTypes.string,
  }).isRequired,
  homephone: PropTypes.shape({
    name: PropTypes.string,
    number: PropTypes.string,
    price: price,
  }),
});

export const energyProps = PropTypes.shape({
  price: price.isRequired,
  discountedPrice: price,
  discountDesc: PropTypes.string,
  electricity: PropTypes.shape({
    price: price.isRequired,
    tariff: PropTypes.string.isRequired,
    annualConsumption: PropTypes.string.isRequired,
    unitRate: PropTypes.string.isRequired,
    standingCharge: PropTypes.string.isRequired,
  }),
  gas: PropTypes.shape({
    price: price.isRequired,
    tariff: PropTypes.string.isRequired,
    annualConsumption: PropTypes.string.isRequired,
    unitRate: PropTypes.string.isRequired,
    standingCharge: PropTypes.string.isRequired,
  }),
});

export const insuranceProps = PropTypes.shape({
  price: price.isRequired,
  boiler: PropTypes.shape({
    price,
  }),
  incomeProtector: PropTypes.shape({
    price,
  }),
});

export const mobileProps = PropTypes.shape({
  price: price.isRequired,
  sims: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      allowance: PropTypes.string.isRequired,
      tariff: PropTypes.string.isRequired,
      price,
    })
  ).isRequired,
});
