import React from 'react';
import Tile from 'app/components/modules/OrderSummary/SummarySection/Shared/Tile';
import PropTypes from 'prop-types';
import { price } from 'app/constants/propTypes';
import PriceWithDiscount from 'modules/Shared/PriceWithDiscount';
import { saving } from 'app/redux/modules/Bundles/propTypes';
import { SavingsList } from 'modules/Bundles/Savings/SavingsList';

import { PRODUCT_INSURANCE } from 'app/redux/modules/Bundles/constants';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import RestrictedButton from 'app/redux/modules/RemoteSession/v2/RestrictedButton';

const useStyles = makeStyles((theme) => ({
  priceContainer: { display: 'flex' },
  savingsContainer: { marginTop: theme.spacing(2) },
  addButton: {
    width: '100%',
  },
}));

const BenefitsTile = ({
  icon,
  id,
  title,
  price,
  addService,
  lockedSavings,
  available,
}) => {
  const classes = useStyles();

  if (id === PRODUCT_INSURANCE && !available) {
    return null;
  }

  const renderPriceSection = () => {
    if (!available) {
      return <Typography>Unavailable in your area</Typography>;
    }

    if (price) {
      return <PriceWithDiscount price={price} estimated responsive />;
    }

    return null;
  };

  return (
    <Tile
      dashed
      fadeTitle
      isEditable
      icon={icon}
      title={title}
      priceSection={renderPriceSection()}
      actionArea={
        available && (
          <RestrictedButton
            variant="outlined"
            onClick={addService}
            label="Add"
            dataTestId={`ServiceAdd${title}`}
            className={classes.addButton}
          />
        )
      }
    >
      <div className={classes.savingsContainer}>
        <SavingsList unlockedSavings={[]} lockedSavings={lockedSavings} />
      </div>
    </Tile>
  );
};
BenefitsTile.propTypes = {
  available: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: price,
  addService: PropTypes.func.isRequired,
  lockedSavings: PropTypes.arrayOf(saving).isRequired,
};

export default BenefitsTile;
