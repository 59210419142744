import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { colors } from '@utilitywarehouse/colour-system';

const style = (theme) => ({
  paper: {
    maxWidth: 960,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      maxWidth: `calc(100% - ${theme.spacing(1)}px)`,
    },
  },
  dialogContent: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 2),
    },
  },
  title: {
    fontSize: 28,
    [theme.breakpoints.up('sm')]: {
      fontSize: 32,
      marginRight: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      lineHeight: 'normal',
    },
  },
  titleImage: {
    maxWidth: 80,
    flexGrow: 0,
    marginLeft: theme.spacing(2),
    objectFit: 'contain',
    '& > img': {
      width: '100%',
    },
  },
  chevronContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  chevron: {
    transform: 'rotate(90deg)',
    color: colors.pink500,
    [theme.breakpoints.up('sm')]: {
      transform: 'rotate(0)',
    },
  },
  detailsContainer: {
    display: 'grid',
    rowGap: `${theme.spacing(3)}px`,
    [theme.breakpoints.up('sm')]: {
      gridTemplateRows: 'none',
      gridTemplateColumns: `1fr ${theme.spacing(4)}px 1fr`,
      columnGap: `${theme.spacing(3)}px`,
      rowGap: 0,
    },
  },
  details: {
    flexGrow: 1,
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&$current': {
      background: colors.grey100,
      color: theme.palette.common.black,
    },
  },
  detailsTitle: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  detailsTitleLabel: {
    fontSize: 24,
    fontWeight: 600,
    color: colors.apple400,
    '&$current': {
      color: colors.red600,
    },
    fontFamily: `Aeonik,${theme.typography.fontFamily}`,
  },
  cardContent: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  current: {},
  summaryText: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: `Aeonik,${theme.typography.fontFamily}`,
  },
  detailsService: {
    marginTop: theme.spacing(2),
  },
  tariffCostTableRow: {
    '& + &': {
      borderTop: `2px solid ${fade(theme.palette.primary.main, 0.2)}`,
    },
  },
  tariffInfoTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  },
  tariffInfoDropdown: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  tariffInfoDropdownLabel: {
    borderColor: `${fade(theme.palette.text.primary, 0.1)}`,
  },
  tariffInfoDropdownContent: {
    padding: theme.spacing(0, 2),
  },
  tariffInfoLabel: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    textAlign: 'left',
    marginRight: '-1px',
  },
  tariffInfoIcon: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  iconGas: {
    color: theme.palette.services.gas,
  },
  iconElec: {
    color: theme.palette.services.electricity,
  },
  inactiveOptionText: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
    opacity: 1,
  },
  activeOptionText: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightMedium,
  },
  description: {
    paddingBottom: theme.spacing(2),
  },
  bold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  switch: {
    marginTop: theme.spacing(1),
  },
  alert: {
    marginTop: theme.spacing(3),

    ['&.MuiPaper-root']: {
      maxWidth: '100%',
    },
  },
});

export default makeStyles(style);
