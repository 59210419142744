import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: `${theme.spacing(4)}px`,
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    padding: theme.spacing(0, 0, 6),
  },
  title: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  item: {
    flexGrow: 1,
  },
  itemContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
  },
  itemButtonBase: {
    height: '100%',
    width: '100%',
  },
  itemHeader: {
    marginBottom: theme.spacing(4),
    textAlign: 'left',
  },
  itemTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: theme.typography.fontWeightMedium,
  },
  itemImage: {
    width: '100%',
    height: theme.spacing(20),
    alignSelf: 'flex-end',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right bottom',
  },
}));
