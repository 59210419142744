import React from 'react';
import {
  broadbandProps,
  energyProps,
  insuranceProps,
  mobileProps,
} from 'app/components/modules/OrderSummary/SummarySection/SelectedServices/Services/propTypes';
import Energy from 'app/components/modules/OrderSummary/SummarySection/SelectedServices/Services/Energy';
import Broadband from 'app/components/modules/OrderSummary/SummarySection/SelectedServices/Services/Broadband';
import Mobile from 'app/components/modules/OrderSummary/SummarySection/SelectedServices/Services/Mobile';
import Insurance from 'app/components/modules/OrderSummary/SummarySection/SelectedServices/Services/Insurance';
import PropTypes from 'prop-types';
import {
  PRODUCT_BROADBAND,
  PRODUCT_ENERGY,
  PRODUCT_INSURANCE,
  PRODUCT_MOBILE,
} from 'app/redux/modules/Bundles/constants';

const SelectedServices = ({
  energy,
  broadband,
  mobile,
  insurance,
  editService,
  removeService,
  isEditable,
  isPartner,
  isSales,
  hasBbTbybPromo,
}) => {
  const makeProps = (id) => ({
    editService: () => editService(id),
    removeService: () => removeService(id),
    isEditable,
  });
  return (
    <>
      {energy && <Energy energy={energy} {...makeProps(PRODUCT_ENERGY)} />}
      {broadband && (
        <Broadband
          broadband={broadband}
          hasBbTbybPromo={hasBbTbybPromo}
          {...makeProps(PRODUCT_BROADBAND)}
        />
      )}
      {mobile && <Mobile mobile={mobile} {...makeProps(PRODUCT_MOBILE)} />}
      {insurance && (
        <Insurance
          insurance={insurance}
          isPartner={isPartner}
          isSales={isSales}
          {...makeProps(PRODUCT_INSURANCE)}
        />
      )}
    </>
  );
};

SelectedServices.propTypes = {
  energy: energyProps,
  broadband: broadbandProps,
  mobile: mobileProps,
  insurance: insuranceProps,
  editService: PropTypes.func.isRequired,
  removeService: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isPartner: PropTypes.bool,
  isSales: PropTypes.bool,
  hasTbybPromo: PropTypes.bool,
};

export default SelectedServices;
