import React, { Fragment, useCallback, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { useTracking } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS } from 'app/lib/analytics/constants';
import { price } from 'app/constants/propTypes';
import PriceWithDiscount from 'modules/Shared/PriceWithDiscount';
import { formatPrice } from 'app/redux/utils/formatter';
import ContentDropdownIcon from 'modules/Shared/ContentDropdown/ContentDropdownIcon';
import useStyles from './styles';
import Tile from 'app/components/modules/OrderSummary/SummarySection/Shared/Tile';
import ActionButton from 'app/components/modules/OrderSummary/SummarySection/Shared/ActionButton';
import FlashSaleBadge from './FlashSaleBadge';
import { isEligibleForBoilerCover } from 'app/redux/modules/App/transitions/api';
import { FeatureFlagsApi } from 'redux/modules/FeatureFlags/api';
import { FLAGS } from 'redux/modules/FeatureFlags/constants';
import { useSelector } from 'react-redux';

const ServiceTile = ({
  icon,
  editService,
  removeService,
  isEditable,
  title,
  price,
  discountedPrice,
  discountDesc,
  items,
  footer,
  isFixed,
  isPartner,
  isSales,
  hasBbTbybPromo,
}) => {
  const analytics = useTracking();
  const classes = useStyles();
  const [openInfoItems, setOpenInfoItems] = useState([]);

  const isInfoItemOpen = useCallback(
    (itemId) => openInfoItems.includes(itemId),
    [openInfoItems]
  );

  const setInfoItemOpen = (itemId, open) => {
    const coreServiceName = title.toLowerCase();

    // mobile "itemId" is an array index, rather than a string ID
    // so reformat the id into something descriptive allowing for
    // snowplow schemas
    const getItemId = (itemId) => {
      if (coreServiceName === 'mobile') {
        return `mobile ${itemId + 1}`;
      }

      return itemId;
    };

    analytics.track(EVENTS.SUMMARY_PRODUCT_DETAIL_CLICK, {
      core_service_name: title.toLowerCase(),
      service_name: getItemId(itemId),
      is_dropdown_open: open,
    });
    if (open) {
      setOpenInfoItems((prev) => [...prev, itemId]);
    } else {
      setOpenInfoItems((prev) => prev.filter((id) => id !== itemId));
    }
  };
  const flashSaleStart = useSelector(
    FeatureFlagsApi.getFlagSelector(FLAGS.INSURANCE_BOILER_FLASH_SALE_START)
  );
  const flashSaleEnd = useSelector(
    FeatureFlagsApi.getFlagSelector(FLAGS.INSURANCE_BOILER_FLASH_SALE_END)
  );

  const isFlashSale =
    new Date(flashSaleStart).getTime() < Date.now() &&
    new Date(flashSaleEnd).getTime() > Date.now();
  const isFlashSaleEligible =
    isEligibleForBoilerCover && isFlashSale && (isPartner || isSales);

  return (
    <div>
      {(discountDesc || isFixed) && (
        <div className={classes.discountLabel}>
          <Typography classes={{ root: classes.discountLabelText }}>
            {isFixed ? 'Fixed tariff unlocked' : discountDesc}
          </Typography>
        </div>
      )}
      <Tile
        dataTestId={`Service${title}`}
        icon={icon}
        title={title}
        className={classes.card}
        isEditable={isEditable}
        actionArea={
          <div className={classes.actionArea}>
            <ActionButton label="Edit" onClick={editService} />
            <ActionButton
              label="Remove"
              onClick={removeService}
              dataTestId={`ServiceRemove${title}`}
            />
          </div>
        }
        priceSection={
          <PriceWithDiscount
            price={price}
            discountedPrice={discountedPrice}
            hasBbTbybPromo={hasBbTbybPromo}
            responsive
          />
        }
        footer={footer}
      >
        <div className={classes.items}>
          {items.map(({ id, name, price, info, subtext }) => {
            const open = isInfoItemOpen(id);
            return (
              <Fragment key={id}>
                {info && (
                  <>
                    <Button
                      variant="text"
                      onClick={() => setInfoItemOpen(id, !open)}
                      classes={{
                        root: classes.infoButton,
                      }}
                      color="default"
                      size="large"
                      endIcon={info && <ContentDropdownIcon open={open} />}
                    >
                      {`${name}${price ? `: ${formatPrice(price)}` : ''}`}
                    </Button>
                    {isFlashSaleEligible && name === 'Boiler & Home Cover' && (
                      <FlashSaleBadge>£10 for first 3 months</FlashSaleBadge>
                    )}
                    {isInfoItemOpen(id) && (
                      <div className={classes.infoContainer}>
                        {Array.from(
                          info,
                          ([label, value]) =>
                            value && (
                              <Typography key={label}>
                                {`${label}:`}{' '}
                                <span className={classes.valueLabel}>
                                  {value}
                                </span>
                              </Typography>
                            )
                        )}
                      </div>
                    )}
                  </>
                )}
                {!info && (
                  <Typography
                    component="div"
                    className={clsx(classes.infoButton, classes.infoText)}
                  >
                    {name}
                    {price && (
                      <>
                        {': '}
                        {formatPrice(price)}
                      </>
                    )}
                    {subtext && (
                      <>
                        {' '}
                        <Typography component="span">{subtext}</Typography>
                      </>
                    )}
                  </Typography>
                )}
              </Fragment>
            );
          })}
        </div>
      </Tile>
    </div>
  );
};

ServiceTile.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  editService: PropTypes.func.isRequired,
  removeService: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  price: price.isRequired,
  discountedPrice: price,
  discountDesc: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      price: price.isRequired,
      info: PropTypes.instanceOf(Map),
    })
  ).isRequired,
  footer: PropTypes.node,
  isFixed: PropTypes.bool,
  isPartner: PropTypes.bool,
  isSales: PropTypes.bool,
  hasBbTbybPromo: PropTypes.bool,
};

export default ServiceTile;
