import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ServiceTile from 'app/components/modules/OrderSummary/SummarySection/Shared/ServiceTile';
import { images } from '@utilitywarehouse/partner-ui-assets';
import { energyProps } from 'app/components/modules/OrderSummary/SummarySection/SelectedServices/Services/propTypes';
import EPGAlert from 'modules/Shared/EPGAlert';
import { TARIFF_TYPES } from 'redux/modules/Energy/Quote/constants';

const makeInfo = ({ tariff, annualConsumption, unitRate, standingCharge }) =>
  new Map([
    ['Tariff', tariff],
    ['Consumption', annualConsumption],
    ['Unit rate', unitRate],
    ['Standing charge', standingCharge],
  ]);

const Energy = ({
  energy: { price, discountedPrice, discountDesc, electricity, gas },
  editService,
  removeService,
  isEditable,
}) => {
  const isFixed =
    gas?.type === TARIFF_TYPES.FIXED ||
    electricity?.type === TARIFF_TYPES.FIXED;
  const items = useMemo(() => {
    const electricityInfo = electricity
      ? {
          id: 'ELECTRICITY',
          name: 'Electricity',
          price: electricity.price,
          info: makeInfo(electricity),
        }
      : undefined;
    const gasInfo = gas
      ? {
          id: 'GAS',
          name: 'Gas',
          price: gas.price,
          info: makeInfo(gas),
        }
      : undefined;
    return [electricityInfo, gasInfo].filter((item) => item !== undefined);
  }, [electricity, gas]);

  return (
    <ServiceTile
      editService={editService}
      removeService={removeService}
      isEditable={isEditable}
      icon={images.icons.productIcons.Electricity}
      title="Energy"
      items={items}
      price={price}
      isFixed={isFixed}
      discountedPrice={discountedPrice}
      discountDesc={discountDesc}
      footer={!isFixed && <EPGAlert />}
    />
  );
};

Energy.propTypes = {
  energy: energyProps,
  isEditable: PropTypes.bool.isRequired,
  editService: PropTypes.func.isRequired,
  removeService: PropTypes.func.isRequired,
};

export default Energy;
