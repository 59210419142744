import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import RestrictedButton from 'redux/modules/RemoteSession/v2/RestrictedButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';

import { useTracking } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS, EVENT_TYPE } from 'app/lib/analytics/constants';

import { useIsMobile } from './utils';
import useStyles from './styles';

import { REQUEST_FETCHING, REQUEST_FAIL } from 'constants/requestStatus';

const SALES_EMAIL = 'salesquote@uw.co.uk';

const SaveForm = ({ email, requestStatus, triggerRequest, isSales }) => {
  const analytics = useTracking();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const isError = requestStatus === REQUEST_FAIL;
  const isSubmitting = requestStatus === REQUEST_FETCHING;

  const handleFormSubmit = useCallback(
    (event) => {
      event.preventDefault();
      triggerRequest();
      analytics.track(EVENTS.APPLICATION_SAVED, {
        event_type: EVENT_TYPE.BEHAVIOUR,
      });
    },
    [triggerRequest, analytics]
  );

  return (
    <DialogContent>
      {isError && (
        <Alert
          severity="error"
          variant="filled"
          className={classes.alertContainer}
        >
          <AlertTitle>
            Something went wrong! Application was not saved.
          </AlertTitle>
        </Alert>
      )}

      <Typography variant="h4" gutterBottom>
        Save
      </Typography>
      {isSales ? (
        <Typography gutterBottom>
          We'll email the sales team a link which will allow them to retrieve
          this quote later.
        </Typography>
      ) : (
        <>
          <Typography gutterBottom>
            We'll email your UW Partner a link which will allow them to retrieve
            this quote at your next appointment.
          </Typography>
          <Typography gutterBottom>
            The link will expire when a new quote is saved or if we make changes
            to our services.
          </Typography>
        </>
      )}

      <form onSubmit={handleFormSubmit} className={classes.formContainer}>
        <TextField
          variant="filled"
          value={(isSales ? SALES_EMAIL : email) || 'Your position email'}
          label={isSales ? 'Sales email' : "Partner's email"}
          disabled
          fullWidth
        />
        <RestrictedButton
          className={classes.formSaveButton}
          variant="contained"
          type="submit"
          size="large"
          disabled={isSubmitting}
          fullWidth={isMobile}
          label={isSubmitting ? 'Saving...' : 'Save and send'}
        />
      </form>
    </DialogContent>
  );
};

SaveForm.propTypes = {
  email: PropTypes.string,
  isSales: PropTypes.bool,
  requestStatus: PropTypes.string,
  triggerRequest: PropTypes.func.isRequired,
};

export default SaveForm;
