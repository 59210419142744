import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { Button } from '@material-ui/core';

import { APPOINTMENT_TYPE } from 'redux/modules/LandingPage/constants';
import {
  MANDATORY,
  CUSTOMER_RELATIONSHIP_REFERRAL,
} from 'redux/modules/PartnerLogin/constants';
import { YOUR_ADDRESS_PATH } from 'redux/modules/EntryForm/sectionPaths.js';

import Referral from './Referral';
import SupportSelection from './SupportSelection';
import RelationshipSelection from './RelationshipSelection';
import RemoteDisplayName, { isDisplayNameValid } from './RemoteDisplayName';
import useStyles from './styles';

export const Relationship = ({
  fields,
  profile,
  updateField,
  hostedId,
  isRemote,
  supportRequired,
  getStartedPartner,
  getStartedRemote,
  resetReferrerFields,
  validateForm,
  updateAnalyticsPage,
  canShowSupportingDialog,
  setSupportingIdDialog,
  supportingLoggedIn,
  updateReferralField,
  appointmentType,
  remoteDisplayName,
  setDisplayName,
  selectedSupport,
  setSupport,
  remoteSupportEnabled,
  isRestoreWorkflow,
  isRemoteRestoreInProgress,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { customerRelationship, isValidForm } = fields;
  const isReferralActive =
    customerRelationship === CUSTOMER_RELATIONSHIP_REFERRAL;
  const isMandatory = supportRequired == MANDATORY;
  const remoteSupport = appointmentType === APPOINTMENT_TYPE.REMOTE_SUPPORT;
  const showSupportingButton =
    !remoteSupportEnabled && !isRemote && !remoteSupport;
  const showSupportSection =
    remoteSupportEnabled && !remoteSupport && canShowSupportingDialog;
  const showTrainingInfo = !isRemote && isMandatory && !supportingLoggedIn;
  const showReferral = isReferralActive && !remoteSupport;
  const showPreferredName = isRemote || remoteSupport;
  const showRelationshipSelection = !remoteSupport && !isRestoreWorkflow;

  const isStartButtonDisabled = () => {
    // can't support a partner remotely if they didn't finsh the training
    const supportMandatory = remoteSupport && isMandatory;

    const faceToFaceSupportRequired =
      !isRemote && isMandatory && !supportingLoggedIn && !remoteSupportEnabled;
    const invalidAppointment =
      !remoteSupport &&
      (!customerRelationship ||
        (isReferralActive && !isValidForm) ||
        faceToFaceSupportRequired);

    const canSelectSupport =
      remoteSupportEnabled &&
      selectedSupport === null &&
      canShowSupportingDialog &&
      appointmentType === APPOINTMENT_TYPE.FACE_TO_FACE;

    const displayNameInvalid = !isDisplayNameValid(remoteDisplayName);

    return (
      isRemoteRestoreInProgress ||
      supportMandatory ||
      canSelectSupport ||
      invalidAppointment ||
      displayNameInvalid
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.widthContainer}>
        {showRelationshipSelection && (
          <RelationshipSelection
            customerRelationship={customerRelationship}
            isReferralActive={isReferralActive}
            resetReferrerFields={resetReferrerFields}
            updateField={updateField}
            validateForm={() => {
              const customerId = get(fields, ['referrer', 'customerId'], null);
              validateForm(customerId);
            }}
          />
        )}
        {showReferral && (
          <Referral
            updateReferralField={updateReferralField}
            validateForm={validateForm}
            fields={fields}
            isRemote={isRemote}
            resetReferrerFields={resetReferrerFields}
          />
        )}
        {showPreferredName && (
          <div className={classes.textFieldClasses}>
            <RemoteDisplayName
              remoteDisplayName={remoteDisplayName}
              setDisplayName={setDisplayName}
            />
          </div>
        )}
        {showTrainingInfo && (
          <Alert className={classes.alert} severity="info">
            We can see that you have not yet completed the Getting Started
            Online Training and therefore you are not yet qualified to sign up
            customers alone. Your supporting partner should log in by clicking
            the button below.
          </Alert>
        )}
        {showSupportSection && !isRemote && (
          <SupportSelection
            selectedSupport={selectedSupport}
            isMandatory={isMandatory}
            onChange={(value) => {
              setSupport(value);
            }}
          />
        )}
        <div
          className={classNames(classes.buttonContainer, {
            [classes.right]: isRemote || remoteSupport || !showSupportingButton,
          })}
        >
          {showSupportingButton && (
            <Button
              size="large"
              variant="outlined"
              data-test-id="ExistingSessionStartAgain"
              disabled={!canShowSupportingDialog}
              onClick={() => setSupportingIdDialog(true)}
            >
              Supporting partner
            </Button>
          )}
          <Button
            size="large"
            variant="contained"
            id="RelationshipTypeButtonId"
            data-test-id="RelationshipTypeButtonId"
            disabled={isStartButtonDisabled()}
            onClick={() => {
              if (remoteSupport) {
                history.push('/remotely/support');
              } else if (isRemote) {
                const profileId = profile.id;
                getStartedRemote(profileId);
              } else {
                getStartedPartner(hostedId);
              }

              const { name, path } = YOUR_ADDRESS_PATH;
              updateAnalyticsPage(name, { path });
            }}
            className={classes.btnNext}
          >
            {remoteSupport
              ? 'Join appointment'
              : isRemoteRestoreInProgress
              ? 'Starting appointment...'
              : 'Start appointment'}
          </Button>
        </div>
      </div>
    </div>
  );
};

Relationship.propTypes = {
  hostedId: PropTypes.string,
  supportRequired: PropTypes.string,
  fields: PropTypes.shape({
    referrer: PropTypes.object,
    errors: PropTypes.object,
    customerRelationship: PropTypes.string,
    isValidForm: PropTypes.bool,
  }).isRequired,
  profile: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
  }).isRequired,
  supportingLoggedIn: PropTypes.bool,
  isRemote: PropTypes.bool.isRequired,
  canShowSupportingDialog: PropTypes.bool.isRequired,
  getStartedPartner: PropTypes.func.isRequired,
  updateField: PropTypes.func.isRequired,
  validateForm: PropTypes.func.isRequired,
  resetReferrerFields: PropTypes.func.isRequired,
  updateReferralField: PropTypes.func.isRequired,
  updateAnalyticsPage: PropTypes.func.isRequired,
  setSupportingIdDialog: PropTypes.func.isRequired,
  getStartedRemote: PropTypes.func.isRequired,
  appointmentType: PropTypes.string.isRequired,
  remoteDisplayName: PropTypes.string,
  setDisplayName: PropTypes.func.isRequired,
  selectedSupport: PropTypes.string,
  setSupport: PropTypes.func.isRequired,
  remoteSupportEnabled: PropTypes.bool,
  isRestoreWorkflow: PropTypes.bool,
  isRemoteRestoreInProgress: PropTypes.bool,
};

export default Relationship;
