import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@utilitywarehouse/colour-system';

const styles = (theme) => ({
  flashSaleBadge: {
    alignSelf: 'flex-start',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'auto',
    backgroundColor: colors.green200,
    borderRadius: '4px',
    height: '22px',
    padding: theme.spacing(0, 1),
  },
  flashSaleBadgeText: {
    color: colors.green900,
    fontSize: 13,
    textTransform: 'none',
    fontWeight: 400,
  },
  discountLabel: {
    display: 'flex',
    position: 'relative',
    textAlign: 'center',
    justifyContent: 'right',
    marginRight: theme.spacing(2),
  },
  discountLabelText: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    justifyContent: 'center',
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(0.5, 2, 0.5, 2),
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    width: 'fit-content',
    lineHeight: 1.2,
  },
  card: {
    border: 'none',
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  infoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  titleContainer: {
    display: 'flex',
    gap: theme.spacing(0, 2),
    alignItems: 'center',
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
  },
  infoButton: {
    padding: 0,
    justifyContent: 'left',
    fontSize: theme.typography.body1.fontSize,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  infoText: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1, 2),
  },
  valueLabel: { fontWeight: theme.typography.fontWeightMedium },
  actionArea: {
    display: 'flex',
    gap: theme.spacing(2, 2),
  },
});

export default makeStyles(styles);
