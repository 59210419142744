import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';
import RestrictedButton from 'app/redux/modules/RemoteSession/v2/RestrictedButton';

const useStyles = makeStyles((theme) => ({
  removeButton: {
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: 0,
    margin: theme.spacing(0),
    padding: theme.spacing(0),
    marginBottom: theme.spacing(1),
    minWidth: 'auto',
  },
}));

const ActionButton = ({ label, onClick, ...props }) => {
  const classes = useStyles();
  return (
    <RestrictedButton
      variant="text"
      color="default"
      size="large"
      classes={{ root: classes.removeButton }}
      onClick={onClick}
      label={label}
      {...props}
    />
  );
};

ActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ActionButton;
