import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import RestrictedButton from 'redux/modules/RemoteSession/v2/RestrictedButton';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';

import { useTracking } from '@utilitywarehouse/partner-tracking-react';
import { EVENTS, EVENT_TYPE } from 'app/lib/analytics/constants';

import { useIsMobile } from './utils';
import useStyles from './styles';

import {
  REQUEST_FETCHING,
  REQUEST_FAIL,
  REQUEST_SUCCESS,
} from 'constants/requestStatus';

const ShareForm = ({
  snapshotId,
  requestStatus,
  resetRequest,
  triggerRequest,
  email,
  onChange,
}) => {
  const analytics = useTracking();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const isError = requestStatus === REQUEST_FAIL;
  const isSubmitting = requestStatus === REQUEST_FETCHING;
  const isSuccess = requestStatus === REQUEST_SUCCESS;

  const handleEmailChange = useCallback(
    (event) => {
      if (event?.target?.value != null) {
        onChange(event.target.value);
      }
    },
    [onChange]
  );

  const handleFormSubmit = useCallback(
    (event) => {
      event.preventDefault();
      triggerRequest(snapshotId, email);
      analytics.track(EVENTS.APPLICATION_SHARED, {
        event_type: EVENT_TYPE.BEHAVIOUR,
      });
    },
    [triggerRequest, email, snapshotId, analytics]
  );

  useEffect(() => {
    if (isSuccess) {
      onChange('');
      resetRequest();
    }
  }, [email, isSuccess, onChange, resetRequest]);

  return (
    <DialogContent>
      {isError && (
        <Alert
          severity="error"
          variant="filled"
          className={classes.alertContainer}
        >
          <AlertTitle>
            Something went wrong! Quote has not been sent.
          </AlertTitle>
        </Alert>
      )}

      <Typography variant="h4" gutterBottom>
        Share
      </Typography>
      <Typography gutterBottom>
        Please note, our email won’t contain a link to retrieve your quote.
      </Typography>

      <form onSubmit={handleFormSubmit} className={classes.formContainer}>
        <TextField
          name="email"
          type="email"
          variant="filled"
          value={email}
          onChange={handleEmailChange}
          label="Prospect's email"
          inputProps={{ readOnly: isSubmitting }}
          fullWidth
          autoFocus
          required
        />
        <RestrictedButton
          className={classes.formShareButton}
          variant="contained"
          type="submit"
          size="large"
          disabled={isSubmitting}
          fullWidth={isMobile}
          label={isSubmitting ? 'Sending...' : 'Send'}
        />
      </form>
    </DialogContent>
  );
};

ShareForm.propTypes = {
  requestStatus: PropTypes.string,
  email: PropTypes.string,
  snapshotId: PropTypes.string.isRequired,
  resetRequest: PropTypes.func.isRequired,
  triggerRequest: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ShareForm;
