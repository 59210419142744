import React from 'react';
import PropTypes from 'prop-types';
import { Box, CardContent, Typography } from '@material-ui/core';
import { CheckCard } from '@utilitywarehouse/partner-ui-mui-components';
import {
  SUPPORT_SELECTION,
  SUPPORT_SELECTION_OPTIONS,
} from 'redux/modules/LandingPage/constants';
import useStyles from './styles';

const SupportSelection = ({ selectedSupport, onChange, isMandatory }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="h4" gutterBottom>
        Do you have a supporting Partner?
      </Typography>
      <Box className={classes.container}>
        {SUPPORT_SELECTION.filter(
          ({ key }) =>
            !(key === SUPPORT_SELECTION_OPTIONS.noSupport && isMandatory)
        ).map(({ key, value: label }) => (
          <CheckCard
            key={key}
            id={`${key}_SupportTypeIdBox`}
            data-test-id={`${key}_SupportTypeIdBox`}
            onChange={() => onChange(key)}
            checked={selectedSupport === key}
            value={key}
            variant="radio"
            name="supportType"
            checkCardClasses={{ root: classes.item }}
          >
            <CardContent>
              <Typography className={classes.itemTitle}>{label}</Typography>
            </CardContent>
          </CheckCard>
        ))}
      </Box>
    </div>
  );
};

SupportSelection.propTypes = {
  selectedSupport: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isMandatory: PropTypes.bool.isRequired,
};

export default SupportSelection;
