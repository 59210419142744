import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import illustrations from '@utilitywarehouse/partner-ui-assets/lib/js/images/illustrations';
import { withStyles } from '@material-ui/core';

import PageHeading from 'components/modules/Shared/PageHeading';
import LoadingIndicator from 'modules/Shared/LoadingIndicator';
import { SUPPORT_MODAL_STATES } from 'redux/modules/PartnerLogin/constants';
import { APPOINTMENT_TYPE } from 'redux/modules/LandingPage/constants';
import {
  REQUEST_FETCHING,
  REQUEST_SUCCESS,
} from '../../../constants/requestStatus';
import AppointmentTypeSelection from './AppointmentTypeSelection';
import Login from './Login';
import Relationship from './Relationship';
import RestoreApp from './RestoreApp';
import styles from './styles';
import SupportingIdDialog from './SupportingIdDialog';

const PartnerLoginComponent = ({
  partnerToken,
  supportingIdDialogOpen,
  profile,
  supportingIdLogin,
  supportingIdError,
  supportingPartnerId,
  requestStatus: { supportingId },
  hostedId,
  loading,
  classes,
  error,
  login,
  getStartedOrganic,
  updateAnalyticsPage,
  setSupportingId,
  setSupportingIdDialog,
  appointmentType,
  appointmentTypeOptions,
  onChangeAppointmentType,
  isRemote,
  remoteSupportEnabled,
  canSupportPartner,
  restoreApplication,
  hasRestoreToken,
  isRemoteRestoreInProgress,
  ...relationshipProps
}) => {
  const canShowSupportingDialog =
    partnerToken && SUPPORT_MODAL_STATES.includes(profile.supportRequired);

  return (
    <div className={classes.container}>
      <PageHeading
        autoScroll={false}
        theme="dark"
        logoVariant="large"
        title={partnerToken && <>Hi, {profile.displayName}</>}
      >
        {!hasRestoreToken && (
          <div className={classes.partnerSelection}>
            <div>
              {!partnerToken && (
                <>
                  <Typography className={classes.title} variant="h1">
                    Start saving today
                  </Typography>
                  <Typography className={classes.subtitle} variant="h3">
                    I&apos;m with a UW Partner
                  </Typography>
                  <Typography variant="body1">
                    <Link
                      underline="always"
                      classes={{ root: classes.link }}
                      onClick={() => {
                        getStartedOrganic(hostedId);
                        updateAnalyticsPage();
                      }}
                    >
                      Click here if you&apos;re not with a UW Partner
                    </Link>
                  </Typography>
                </>
              )}
            </div>
            {!partnerToken && (
              <img
                className={classes.image}
                alt="woman image"
                src={illustrations.i6.outlined}
              />
            )}
          </div>
        )}
        {hasRestoreToken && partnerToken && (
          <div className={classes.partnerSelection}>
            <div>
              <Typography className={classes.title} variant="h1">
                Hi, {profile.displayName}
              </Typography>
            </div>
          </div>
        )}
      </PageHeading>
      {!partnerToken &&
        (loading ? (
          <LoadingIndicator loading />
        ) : (
          <Box
            mt={hasRestoreToken ? { xs: 2, sm: 8 } : 0}
            mb={hasRestoreToken ? { xs: 2, sm: 5 } : 0}
          >
            <Login login={login} error={error} hostedId={hostedId} />
          </Box>
        ))}
      {partnerToken && (
        <>
          {!relationshipProps.loading && (
            <AppointmentTypeSelection
              options={appointmentTypeOptions}
              canSupportPartner={canSupportPartner}
              isRestoreWorkflow={hasRestoreToken}
              appointmentType={appointmentType}
              onChange={(value) => {
                onChangeAppointmentType(value);
                setSupportingId(null);
              }}
            />
          )}
          {appointmentType !== undefined &&
            appointmentType !== APPOINTMENT_TYPE.RESTORE && (
              <Relationship
                profile={profile}
                hostedId={hostedId}
                isRemote={isRemote}
                supportingPartnerId={supportingPartnerId}
                updateAnalyticsPage={updateAnalyticsPage}
                setSupportingId={setSupportingId}
                setSupportingIdDialog={setSupportingIdDialog}
                canShowSupportingDialog={canShowSupportingDialog}
                supportRequired={profile.supportRequired}
                supportingLoggedIn={
                  supportingId === REQUEST_SUCCESS ||
                  !isEmpty(supportingPartnerId)
                }
                appointmentType={appointmentType}
                remoteSupportEnabled={remoteSupportEnabled}
                isRestoreWorkflow={hasRestoreToken}
                isRemoteRestoreInProgress={isRemoteRestoreInProgress}
                {...relationshipProps}
              />
            )}
          {appointmentType === APPOINTMENT_TYPE.RESTORE && (
            <RestoreApp restoreApplication={restoreApplication} />
          )}
        </>
      )}
      {canShowSupportingDialog && !isRemote && (
        <SupportingIdDialog
          hostedId={hostedId}
          open={supportingIdDialogOpen}
          error={supportingIdError}
          supportRequired={profile.supportRequired}
          success={supportingId === REQUEST_SUCCESS || supportingPartnerId}
          loading={supportingId === REQUEST_FETCHING}
          onSubmit={(id, password) => {
            supportingIdLogin(id, password, profile.id);
          }}
          onClose={() => setSupportingIdDialog(false)}
        />
      )}
    </div>
  );
};

PartnerLoginComponent.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  hostedId: PropTypes.string,
  partnerToken: PropTypes.string,
  appointmentType: PropTypes.string,
  supportingPartnerId: PropTypes.string,
  supportingIdError: PropTypes.string,
  profile: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
    supportRequired: PropTypes.string,
  }).isRequired,
  isRemote: PropTypes.bool.isRequired,
  requestStatus: PropTypes.shape({ supportingId: PropTypes.string }),
  supportingIdDialogOpen: PropTypes.bool.isRequired,
  appointmentTypeOptions: PropTypes.array,
  login: PropTypes.func.isRequired,
  supportingIdLogin: PropTypes.func.isRequired,
  getStartedOrganic: PropTypes.func.isRequired,
  setSupportingId: PropTypes.func.isRequired,
  setSupportingIdDialog: PropTypes.func.isRequired,
  updateAnalyticsPage: PropTypes.func.isRequired,
  onChangeAppointmentType: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  remoteSupportEnabled: PropTypes.bool,
  canSupportPartner: PropTypes.bool.isRequired,
  restoreApplication: PropTypes.func,
  hasRestoreToken: PropTypes.bool,
  isRemoteRestoreInProgress: PropTypes.bool,
};

export default withStyles(styles)(PartnerLoginComponent);
