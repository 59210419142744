import React from 'react';
import PropTypes from 'prop-types';
import { Box, CardContent, Typography } from '@material-ui/core';
import { CheckCard } from '@utilitywarehouse/partner-ui-mui-components';

import SectionCentered from 'modules/layout/SectionCentered';
import { useStyles } from './styles';
import { APPOINTMENT_TYPE } from 'redux/modules/LandingPage/constants';

const AppointmentTypeSelection = ({
  appointmentType,
  options,
  onChange,
  canSupportPartner,
  isRestoreWorkflow,
}) => {
  const classes = useStyles();
  return (
    <SectionCentered>
      <Typography variant="h4" component="h2" className={classes.title}>
        Which type of appointment would you like to set up?
      </Typography>
      <Box className={classes.container}>
        {options
          .filter(
            ({ type }) =>
              !(
                (!canSupportPartner || isRestoreWorkflow) &&
                type === APPOINTMENT_TYPE.REMOTE_SUPPORT
              )
          )
          .map(({ type, title, description, image }) => (
            <CheckCard
              key={type}
              id={`${type}_AppointmentTypeIdBox`}
              data-test-id={`${type}_AppointmentTypeIdBox`}
              onClick={() => {
                onChange(type);
              }}
              checkCardClasses={{
                root: classes.item,
                buttonBase: classes.itemButtonBase,
              }}
              checked={appointmentType === type}
              value={type}
              variant="radio"
              name="appointmentType"
            >
              <CardContent classes={{ root: classes.itemContent }}>
                <header className={classes.itemHeader}>
                  <Typography className={classes.itemTitle}>{title}</Typography>
                  {description && (
                    <Typography className={classes.itemDescription}>
                      {description}
                    </Typography>
                  )}
                </header>
                <Box
                  className={classes.itemImage}
                  style={{ backgroundImage: `url(${image})` }}
                />
              </CardContent>
            </CheckCard>
          ))}
      </Box>
    </SectionCentered>
  );
};

AppointmentTypeSelection.propTypes = {
  appointmentType: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  canSupportPartner: PropTypes.bool.isRequired,
  isRestoreWorkflow: PropTypes.bool,
};

export default AppointmentTypeSelection;
