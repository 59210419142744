import { Card, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    border: 'none',
    padding: theme.spacing(2, 3),
  },
  dashedContainer: {
    borderStyle: 'dashed',
    borderColor: theme.palette.text.primary,
    borderWidth: 1,
    backgroundColor: 'transparent',
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  unselectedTitleRow: {
    opacity: 0.6,
  },
  infoRow: {
    display: 'flex',
    gap: theme.spacing(2, 2),
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'end',
    },
  },
  footerRow: {
    marginTop: theme.spacing(1),
  },
  titleContainer: {
    display: 'flex',
    gap: theme.spacing(0, 2),
    alignItems: 'center',
  },
  title: {
    fontSize: 22,
    [theme.breakpoints.up('sm')]: {
      fontSize: 'auto',
    },
  },
  actionArea: {
    width: '100%',
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  icon: {
    width: 24,
    height: 24,
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      height: 'auto',
    },
  },
}));

const Tile = ({
  icon,
  title,
  priceSection,
  actionArea,
  children,
  dashed,
  fadeTitle,
  dataTestId,
  isEditable = false,
  footer = null,
}) => {
  const classes = useStyles();
  return (
    <Card
      data-test-id={dataTestId}
      classes={{
        root: classNames(dashed && classes.dashedContainer, classes.card),
      }}
    >
      <div
        className={classNames(
          classes.titleRow,
          fadeTitle && classes.unselectedTitleRow
        )}
      >
        <div className={classes.titleContainer}>
          {typeof icon === 'string' ? (
            <img className={classes.icon} alt={title} src={icon} />
          ) : (
            icon
          )}
          <Typography variant={'h4'} className={classes.title}>
            {title}
          </Typography>
        </div>
        {priceSection}
      </div>
      <div className={classes.infoRow}>
        {children}
        {isEditable && <div className={classes.actionArea}>{actionArea}</div>}
      </div>
      <footer className={classes.footerRow}>{footer}</footer>
    </Card>
  );
};

Tile.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  title: PropTypes.node.isRequired,
  priceSection: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node.isRequired,
  actionArea: PropTypes.node,
  dashed: PropTypes.bool,
  fadeTitle: PropTypes.bool,
  dataTestId: PropTypes.string,
  isEditable: PropTypes.bool,
};

export default Tile;
