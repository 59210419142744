import { makeStyles } from '@material-ui/core';

export default makeStyles(({ spacing, typography }) => ({
  radioContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  subtitle: {
    fontSize: 20,
    marginBottom: spacing(2),
  },
  item: {
    '& + &': {
      marginTop: 0,
      marginLeft: spacing(2),
    },
  },
  itemTitle: {
    fontSize: 18,
    fontWeight: typography.fontWeightMedium,
  },
}));
