import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

import SaveForm from './SaveForm';
import ShareForm from './ShareForm';

import { useTracking } from '@utilitywarehouse/partner-tracking-react';
import { useIsMobile } from './utils';

import { EVENTS, EVENT_TYPE } from 'app/lib/analytics/constants';

import useStyles from './styles';

const Save = ({
  isSales,
  isApplicationComplete,
  isApplicationReadOnly,
  partnerEmail,
  shareEmail,
  resetSave,
  resetShare,
  resetSnapshotId,
  saveStatus,
  shareStatus,
  snapshotId,
  triggerSave,
  triggerShare,
  setShareEmail,
  shareDialogOpen,
  setShareDialog,
}) => {
  const analytics = useTracking();
  const classes = useStyles();
  const hidden = isApplicationReadOnly || isApplicationComplete;
  const isMobile = useIsMobile();

  useEffect(() => {
    return () => {
      // reset snapshotId (application saved) on component unmount - page leave
      resetSnapshotId();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDialogOpen = useCallback(() => {
    setShareDialog(true);
    analytics.track(EVENTS.APPLICATION_SAVE_AND_SHARE_DIALOG_OPENED, {
      event_type: EVENT_TYPE.BEHAVIOUR,
    });
  }, [setShareDialog, analytics]);

  const handleDialogClose = useCallback(() => {
    resetSave();
    resetShare();
    setShareDialog(false);
  }, [setShareDialog, resetSave, resetShare]);

  return hidden ? null : (
    <>
      <Button variant="text" size="large" onClick={handleDialogOpen} fullwidth>
        Save and share
      </Button>
      <Dialog
        open={shareDialogOpen}
        onClose={handleDialogClose}
        fullWidth
        maxWidth="sm"
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle>Save and share</DialogTitle>
        {snapshotId ? (
          <ShareForm
            snapshotId={snapshotId}
            triggerRequest={triggerShare}
            requestStatus={shareStatus}
            resetRequest={resetShare}
            email={shareEmail}
            onChange={setShareEmail}
          />
        ) : (
          <SaveForm
            email={partnerEmail}
            triggerRequest={triggerSave}
            requestStatus={saveStatus}
            snapshotId={snapshotId}
            isSales={isSales}
          />
        )}
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={handleDialogClose}
            color="secondary"
            variant="outlined"
            fullWidth={isMobile}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

Save.propTypes = {
  isSales: PropTypes.bool,
  isApplicationComplete: PropTypes.bool,
  isApplicationReadOnly: PropTypes.bool,
  partnerEmail: PropTypes.string,
  shareEmail: PropTypes.string,
  resetSave: PropTypes.func.isRequired,
  resetShare: PropTypes.func.isRequired,
  resetSnapshotId: PropTypes.func.isRequired,
  saveStatus: PropTypes.string,
  shareStatus: PropTypes.string,
  snapshotId: PropTypes.string,
  triggerSave: PropTypes.func.isRequired,
  triggerShare: PropTypes.func.isRequired,
  setShareEmail: PropTypes.func.isRequired,
  shareDialogOpen: PropTypes.bool.isRequired,
  setShareDialog: PropTypes.func.isRequired,
};

export default Save;
